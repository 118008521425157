import React from 'react'

import starEmpty from '../../../../assets/images/icon_star_white.png'
import starFull from '../../../../assets/images/icon_star_green.png'

interface IProps {
  index: number
  active: boolean
  onPress: (index: number) => void
}

export default class Star extends React.PureComponent<IProps> {
  public render(): JSX.Element {
    const { active, index, onPress } = this.props

    return (
      <div className={'rater__oneStarContainer'}>
        <div className={'rater__oneStarImgContainer'} onClick={this.onPress} key={`episode-vote-star-${index}`}>
          <img src={active ? starFull : starEmpty} className={'rater__oneStarImg'} />
        </div>
      </div>
    )
  }

  private onPress = () => {
    this.props.onPress(this.props.index)
  }
}
