import React from 'react'
import { validate } from 'validate.js'
import theme from '../../theme'
import i18n from '../../tools/i18n'
import Button from '../Button'
import CGU from '../Inputs/CGU'
import EmailChecker from '../Inputs/EmailChecker'
import InputText from '../Inputs/InputText'
import GreyUpSpace from '../Texts/GreyUpScale'

interface IProps {
  forms: IFormObject[]
  placeholder?: boolean
  defaultValues?: IFormValue[]
  buttonText: string
  onValidSubmit: (values: { [key: string]: string }) => void
  loading: boolean
  mainError?: boolean
  mainErrorText?: string
  success?: boolean
  successText?: string
  injectedErrors?: { [key: string]: string[] }
  resetOnSuccess?: boolean
  lang?: 'fr' | 'en'
}
interface IState {
  values: IFormValue[]
  loading: boolean
  errors: { [key: string]: string[] }
}
interface IFormValue {
  name: string
  value: string
}
export interface IFormObject {
  title: string
  name: string
  locked?: boolean
  type: 'text' | 'password' | 'CGU' | 'emailCheck'
  displayDependency?: { value: any; name: string }
  half: boolean
  validation: any
  legend?: string
}
export default class Form extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    let values: IFormValue[] = []
    props.forms.forEach((f) => {
      let v = this.props.defaultValues?.find((dv) => dv.name === f.name)
      values.push({ name: f.name, value: v?.value || '' })
    })
    this.state = { values, loading: false, errors: props.injectedErrors ? props.injectedErrors : {} }
  }
  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.injectedErrors !== this.props.injectedErrors) {
      this.setState({
        errors: {
          ...this.state.errors,
          ...this.props.injectedErrors,
        },
      })
    }
    if (this.props.resetOnSuccess && !prevProps.success && this.props.success) {
      this.setState({ values: [] })
    }
  }
  public render(): JSX.Element {
    const { forms, mainErrorText, successText } = this.props
    console.log('tototootototo')
    return (
      <div
        style={{
          flex: '1 1 auto',
        }}
      >
        {forms.map((form: IFormObject) => {
          return this.generateInput(form)
        })}
        <Button onClick={this.onSubmit} loading={this.state.loading || this.props.loading} theme={'primary'}>
          {this.props.buttonText}
        </Button>
        {this.props.mainError && mainErrorText && (
          <div style={{ color: theme.colors.error, padding: '5px', marginTop: '5px', fontSize: '15px' }}>
            {mainErrorText}
          </div>
        )}
        {this.props.success && this.props.successText && (
          <div style={{ color: theme.colors.success, padding: '5px', marginTop: '5px', fontSize: '15px' }}>
            {successText}
          </div>
        )}
      </div>
    )
  }

  private onSubmit = () => {
    this.setState({ loading: true }, () => {
      let validators: any = {}
      let values: { [key: string]: string } = {}
      this.props.forms.forEach((f: IFormObject) => {
        if (!f.locked) {
          validators[f.name] = f.validation
          values[f.name] = this.state.values.find((v) => v.name === f.name)?.value || ''
        }
      })

      const errors = validate(values, validators, { fullMessages: false })
      if (errors !== undefined) {
        this.setState({ errors, loading: false })
      } else {
        this.setState({ errors: {}, loading: false })
        this.props.onValidSubmit(values)
      }
    })
  }

  private generateInput = (form: IFormObject): JSX.Element => {
    /*const dpD = form.displayDependency
    const renderCondition =
      dpD === undefined
        ? true
        : dpD.name &&
          dpD.value === undefined &&
          this.state.values.find((v) => {
            v.name === form.name
          }) !== undefined
        ? true
        : dpD.name &&
          dpD.value !== undefined &&
          dpD.value ===
            this.state.values.find((v) => {
              v.name === form.name
            })
        ? true
        : false */
    const fv = this.state.values.find((v) => v.name === form.name)
    let formEl: JSX.Element | undefined = undefined
    const errored: string | undefined = Object.keys(this.state.errors).find((k) => k === form.name)
    switch (form.type) {
      case 'CGU':
        return (
          <div>
            <CGU
              key={`register_input_${form.name}`}
              name={form.name}
              onChange={this.onChange}
              value={(fv && fv.value) || 'false'}
              locked={form.locked}
              style={{}}
              lang={this.props.lang}
            />

            {errored &&
              this.state.errors[errored].map((ee: string) => {
                return ee.includes('NONE') ? (
                  false
                ) : (
                  <span style={{ color: theme.colors.error, padding: 5 }}>{i18n.t(ee)}</span>
                )
              })}
          </div>
        )
      case 'emailCheck':
        formEl = (
          <EmailChecker
            key={`register_input_${form.name}`}
            name={form.name}
            onChange={this.onChange}
            style={{ width: '100%' }}
            placeholder={(this.props.placeholder && i18n.t(form.title)) || ''}
            value={(fv && fv.value) || ''}
            locked={form.locked}
            lang={this.props.lang}
          />
        )
        break
      case 'password':
      case 'text':
      default:
        formEl = (
          <InputText
            key={`register_input_${form.name}`}
            name={form.name}
            onChange={this.onChange}
            style={{ width: '100%' }}
            placeholder={(this.props.placeholder && i18n.t(form.title)) || ''}
            secureTextEntry={form.type === 'password'}
            value={(fv && fv.value) || ''}
            locked={form.locked}
            lang={this.props.lang}
          />
        )
    }
    if (!this.props.placeholder) {
      return (
        <div>
          <div
            style={{
              backgroundColor: theme.colors.greys.light,
              marginTop: 5,
              borderRadius: 3,
              ...(errored ? { border: `1px solid ${theme.colors.error}` } : {}),
            }}
          >
            <GreyUpSpace style={{ marginTop: '0', marginLeft: '8px', marginBottom: '5px' }} text={i18n.t(form.title)} />
            {formEl}

            {errored &&
              this.state.errors[errored].map((ee: string) => {
                return ee.includes('NONE') ? (
                  false
                ) : (
                  <span style={{ color: theme.colors.error, padding: '0 5px' }}>{ee}</span>
                )
              })}
          </div>
          {form.legend && (
            <span
              style={{
                fontSize: '12px',
                paddingRight: '10px',
                marginTop: '2px',
                lineHeight: '15px',
                color: `${theme.colors.white}50`,
              }}
            >
              {i18n.t(form.legend)}
            </span>
          )}
        </div>
      )
    }
    return formEl
  }
  private onChange = (name: string, value: string) => {
    this.setState({
      values: this.state.values.map((v) =>
        v.name === name
          ? {
              name,
              value,
            }
          : v,
      ),
    })
  }
}
