const ENV: IEnvsConfig = {
  dev: {
    apiUrl: 'http://localhost:10000',
  },
  prod: {
    apiUrl: 'https://api.fasterclass.fr',
  },
}
interface IEnvsConfig {
  dev: IConfig
  prod: IConfig
}
interface IConfig {
  apiUrl: string
}
export interface IConfigDev {
  apiUrl: {
    ios: string
    android: string
  }
}

export default ENV.prod
