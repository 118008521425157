import React from 'react'
import { IViewExtended } from '../../../store/reader/reducer'
import { IHomeProgramObject } from '../../../store/programs/reducer'
import './ProgramCard.scss'
import imageResizer from '../../../tools/imageResizer'
import moment from 'moment'
import { getProgramPercent, getProgramPercentHome, isPlayableProgram } from '../../../store/programs/tools'
import theme from '../../../theme'
import { Check, Clock } from 'react-feather'
import intercom from '../../../tools/intercom'
import { useHistory } from 'react-router-dom'
import i18n from '../../../tools/i18n'
import frFlag from './assets/france-flag-icon-32.png'
import enFlag from './assets/united-kingdom-flag-icon-64.png'

interface IProps {
  views: IViewExtended[]
  program: IHomeProgramObject
  lang: 'fr' | 'en'
  goToProgram: (programId: number) => void
}

class ProgramCard extends React.Component<IProps, any> {
  render() {
    const { program, lang } = this.props
    return (
      <div
        className="programcard"
        style={{ backgroundImage: `url(${imageResizer(program.picture, 'homecaroussel')})` }}
        onClick={this.onProgramClick}
      >
        <div className="programcard__blackFilter">
          <div className="programcard__spacer" />
          <div className="programcard__infos">
            {program.horsserie ? (
              <div className="programcard__titletag">{i18n.t('pages.online.home.hs')}</div>
            ) : (
              <div className="programcard__titletagempty">empty</div>
            )}
            {program.imageTitle && program.imageTitle !== '' ? (
              <div className="programcard__title">
                <img
                  src={lang === 'fr' ? program.imageTitle : program.imageTitleEn}
                  style={{ height: '140px', maxWidth: '320px', objectFit: 'contain' }}
                />
              </div>
            ) : lang === 'fr' ? (
              <div className="programcard__title">
                <span>{program.title.split('$')[0]?.toUpperCase() || program.title}</span>
                {program.title.split('$')[1]?.toUpperCase() || ''}
              </div>
            ) : (
              <div className="programcard__title">
                <span>{program.titleEn.split('$')[0]?.toUpperCase() || program.titleEn}</span>
                {program.titleEn.split('$')[1]?.toUpperCase() || ''}
              </div>
            )}

            <div className="programcard__subtitle">
              {' '}
              {lang === 'fr' ? program.titlePicture : program.titlePictureEn}
            </div>

            {this.getDetailsBlock()}
          </div>
        </div>
      </div>
    )
  }
  private getDetailsBlock = () => {
    const { program } = this.props
    const momentdif = moment().diff(moment(program.available))

    if (isPlayableProgram(program) && momentdif >= 0) {
      return (
        <div className="programcard__moreinfos">
          <div className="programcard__details">
            <div className="programcard__priceContainer">
              <div className="programcard__priceTitle">
                {getProgramPercentHome(program, this.props.views) === 100
                  ? i18n.t('pages.online.home.ended')
                  : i18n.t('pages.online.home.inprogress')}
              </div>
              <div className="programcard__price">{getProgramPercentHome(program, this.props.views)} %</div>
            </div>
            <div className="programcard__priceContainer2">
              <div className="programcard__priceTitle">{i18n.t('pages.online.home.langavailable')}</div>
              <div className="programcard__lang">
                <img src={frFlag} />
                {program.enReady && <img src={enFlag} />}
              </div>
            </div>
          </div>
        </div>
      )
    } else if (!isPlayableProgram(program) && momentdif >= 0) {
      return (
        <div className="programcard__moreinfos">
          <div className="programcard__details">
            <div className="programcard__priceContainer">
              <div className="programcard__priceTitle">
                {program.price > 0 ? i18n.t('pages.online.home.p1') : i18n.t('pages.online.home.p2').toUpperCase()}
              </div>
              <div className="programcard__price">
                {program.price > 0
                  ? `${program.price} € ${i18n.t('pages.online.home.price')}`
                  : i18n.t('pages.online.home.free')}
              </div>
            </div>

            <div className="programcard__priceContainer2">
              <div className="programcard__priceTitle">{i18n.t('pages.online.home.langavailable')}</div>
              <div className="programcard__lang">
                <img src={frFlag} />
                {program.enReady && <img src={enFlag} />}
              </div>
            </div>
          </div>
        </div>
      )
    } else if (!isPlayableProgram(program) && momentdif < 0) {
      return (
        <div className="programcard__moreinfos">
          <div className="programcard__details">
            <div className="programcard__priceContainer">
              <div className="programcard__priceTitle"></div>
              <div className="programcard__price">{i18n.t('pages.online.home.free')}</div>
            </div>

            <div className="programcard__moreDetails">
              <Clock color={`${theme.colors.white}80`} size={14} /> {i18n.t('pages.online.home.soonav')}
            </div>
          </div>
        </div>
      )
    } else if (isPlayableProgram(program) && momentdif < 0) {
      return (
        <div className="programcard__moreinfos">
          <div className="programcard__details">
            <div className="programcard__priceContainer">
              <div className="programcard__priceTitle"></div>
              <div className="programcard__price">{i18n.t('pages.online.home.owned')}</div>
            </div>

            <div className="programcard__moreDetails">
              <Clock color={`${theme.colors.white}80`} size={14} /> {i18n.t('pages.online.home.soonav')}
            </div>
          </div>
        </div>
      )
    }
  }
  private onProgramClick = () => {
    const { program } = this.props
    const momentdif = moment().diff(moment(program.available))

    if (isPlayableProgram(program) && momentdif >= 0) {
      this.props.goToProgram(program.id)
    } else if (!isPlayableProgram(program) && momentdif >= 0) {
      this.props.goToProgram(program.id)
    } else if (!isPlayableProgram(program) && momentdif < 0) {
      intercom.showNewMessage(
        i18n.t('pages.online.home.intercominterested', { progTitle: program.title.replace('$', '') }),
      )
    } else if (isPlayableProgram(program) && momentdif < 0) {
      intercom.showNewMessage(
        i18n.t('pages.online.home.intercominterested', { progTitle: program.title.replace('$', '') }),
      )
    }
  }
}

export default ProgramCard
