import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import React from 'react'
import queryString from 'query-string'
import API from '../../../tools/fetch'
import Loader from '../../../components/Loader'
import { IStore } from '../../../store'
import imageResizer from '../../../tools/imageResizer'
import './ProgramDisplay.scss'
import i18n from '../../../tools/i18n'

interface IProps {
  programId: number
}
interface IState {
  loading: boolean
  program:
    | {
        id: number
        description: string
        title: string
        picture: string
        titlePicture: string
        price: number
        available: string
        cpfready: string
        horsserie: string
      }
    | undefined
}

class ProgramDisplay extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      loading: true,
      program: undefined,
    }
  }
  async componentDidMount() {
    const response = await API.get('/public/payment/program', { params: { programId: this.props.programId } })
    this.setState({
      loading: false,
      program: response.data,
    })
  }
  public render(): JSX.Element {
    const { program, loading } = this.state
    if (!program) return <Loader loading={true} />
    return (
      <div
        className="programDisplayCheckout"
        style={{ backgroundImage: `url(${imageResizer(program.picture, 'homecaroussel')})` }}
      >
        <div className="programDisplayCheckout__blackFilter">
          <div className="programDisplayCheckout__spacer" />
          <div className="programDisplayCheckout__infos">
            {program.horsserie && (
              <div className="programDisplayCheckout__titletag">{i18n.t('pages.checkout.comboDisplay.hs')}</div>
            )}
            <div className="programDisplayCheckout__title">
              <span>{program.title.split('$')[0]?.toUpperCase() || program.title}</span>
              {program.title.split('$')[1]?.toUpperCase() || ''}
            </div>

            {/*<div className="programDisplayCheckout__subtitle"> {program.titlePicture}</div>*/}

            <div className="programcard__moreinfos">
              <div className="programcard__details">
                <div className="programcard__priceContainer">
                  <div className="programcard__priceTitle">{i18n.t('pages.checkout.comboDisplay.tarif')}</div>
                  <div className="programcard__price">
                    {program.price > 0 ? `${program.price} € TTC` : i18n.t('pages.checkout.comboDisplay.free')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((store: IStore) => ({
  logged: store.user.logged,
}))(ProgramDisplay)
