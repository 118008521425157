import React from 'react'
import { connect } from 'react-redux'
import '../LoginCheckout/SignIn.scss'
import StepManager from '../../../pages/offline/registerStep/Steps/stepManager'
import i18n from '../../../tools/i18n'

interface IProps {
  dispatch: (arg: { type: string; payload?: any }) => void
  toggleLogin: () => void
}

class RegisterCheckout extends React.Component<IProps> {
  public render(): JSX.Element | null {
    return (
      <div className="signin">
        <StepManager dispatch={this.props.dispatch} lang={'fr'} />
        <span className="signin__link">
          {i18n.t(`pages.offline.login.lineCreate`)}{' '}
          <a style={{ cursor: 'pointer' }} onClick={this.props.toggleLogin}>
            {i18n.t(`pages.offline.login.createAction`)}
          </a>
        </span>
      </div>
    )
  }
}

export default connect()(RegisterCheckout)
