import { USER_LOGOUT } from '../user/constants'
import {
  PROGRAMS_FETCH,
  PROGRAMS_FETCH_ERROR,
  PROGRAMS_FETCH_REQUESTING,
  PROGRAMS_FETCH_SUCCESS,
  PROGRAM_FETCH_ERROR,
  PROGRAM_FETCH_REQUESTING,
  PROGRAM_FETCH_SUCCESS,
} from './constants'

export interface IHomeProgramObject {
  id: number
  description: string
  title: string
  picture: string
  titlePicture: string
  certification: string
  price: number
  available: string
  purchase: {
    date: string
    price: number
    complete: boolean
    status: string
  } | null
  episodes: number[]
  buyUrl: string
  horsserie: boolean
  cpfready: boolean
  imageTitle?: string

  titleEn: string
  descriptionEn: string
  imageTitleEn: string
  titlePictureEn: string
  enReady: boolean
}

export interface IProgramObject {
  id: number
  description: string
  title: string
  picture: string
  titlePicture: string
  certification: string
  price: number
  available: string
  purchase: {
    date: string
    price: number
    complete: boolean
    status: string
  } | null
  mentor: IMentorObject
  episodes: IEpisodeObject[]
  buyUrl: string
  horsserie: boolean
  cpfready: boolean
  classbookUrl?: string
  classbookImg?: string
  imageTitle?: string

  titleEn: string
  descriptionEn: string
  imageTitleEn: string
  titlePictureEn: string
  enReady: boolean
  classbookUrlEn?: string
}

export interface IEpisodeObject {
  id: number
  title: string
  description: string
  picture: string
  duration: number
  postit: string
  order: number
  chapter: string
  video: string
  audio: string
  mentor: IMentorObject
  view: IViewObject | null
  ressources: IRessourceObject[]
  actions: IActionObject[]
  subtitles: ISubtitleObject[]
  introduction: boolean
  trailer: boolean
  free: boolean
  videoid?: string

  titleEn: string
  descriptionEn: string
  durationEn: number
  chapterEn: string
  videoidEn?: string
  mentorEn: IMentorObject

  postitEn: string
}

export interface IActionObject {
  id: number
  title: string
  description: string
  titleEn: string
  descriptionEn: string
}

export interface IViewObject {
  currentDuration: number
  complete: boolean
  date: string
  note: number
  type: string
}

export interface IRessourceObject {
  id: number
  image: string
  title: string
  titleEn: string
  type: string
  file: string
  fileEn: string
}

export interface IMentorObject {
  id: number
  firstname: string
  lastname: string
  picture: string
  bio: string
  description: string
  bioEn: string
  descriptionEn: string
  videoId: string
  videoUrl: string
  experiences: IExperienceObject[]
}

export interface IExperienceObject {
  id: number
  picture: string
  company: string
  job: string
  tag: string
  companySize: number
  companyTime: number
  order: number
}

export interface IProgramsState {
  isRequesting: boolean
  programs: IHomeProgramObject[]
  program: IProgramObject | undefined
  programRequesting: boolean
  categories: ICategoriesProg[]
  error: boolean
}

export interface ICategoriesProg {
  id: number
  categoryName: string
  categoryNameEn: string
  order: number
  programs: number[]
}
export interface ISubtitleObject {
  id: number
  lang: string
  langShort: string
  url: string
  epLang: string
}

const initialState: IProgramsState = {
  isRequesting: true,
  programs: [],
  program: undefined,
  programRequesting: true,
  categories: [],
  error: false,
}

export default (
  state: IProgramsState = initialState,
  { type, payload }: { type: string; payload: any },
): IProgramsState => {
  switch (type) {
    case PROGRAMS_FETCH_REQUESTING:
      return {
        ...state,
        isRequesting: true,
        error: false,
      }
    case PROGRAMS_FETCH_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: true,
      }
    case PROGRAMS_FETCH_SUCCESS:
      return {
        ...state,
        programs: payload.programs,
        categories: payload.categories,
        isRequesting: false,
        error: false,
      }

    case PROGRAM_FETCH_REQUESTING:
      return {
        ...state,
        programRequesting: true,
        error: false,
      }
    case PROGRAM_FETCH_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: true,
      }
    case PROGRAM_FETCH_SUCCESS:
      return {
        ...state,
        program: payload,
        programRequesting: false,
        error: false,
      }

    case USER_LOGOUT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
