import React from 'react'
import { IStore } from '../../../store'
import { connect } from 'react-redux'
import { LOGIN, LOGIN_ERROR } from '../../../store/user/constants'
import theme from '../../../theme'
import API from '../../../tools/fetch'
import { AxiosResponse } from 'axios'
import Form from '../../../components/Form'
import inputs from './form2'
import inputsBasic from './form1'
import { Link } from 'react-router-dom'
import i18n from '../../../tools/i18n'

interface IProps {
  dispatch: (object: { type: string; payload: any }) => void
}

interface IState {
  email: string
  requesting: boolean
  errored: boolean
  step: '1' | '2'
  success: boolean
}
class ResetPassword extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      email: '',
      requesting: false,
      errored: false,
      step: '1',
      success: false,
    }
    this.props.dispatch({ type: LOGIN_ERROR, payload: '' })
  }

  public render(): JSX.Element | null {
    if (this.state.step === '1')
      return (
        <div className="signin">
          <Form
            forms={inputsBasic}
            defaultValues={[]}
            buttonText={i18n.t('pages.offline.resetPwd.btnTxt1')}
            onValidSubmit={this.onRequest}
            loading={this.state.requesting}
            mainError={this.state.errored}
            mainErrorText={i18n.t('pages.offline.resetPwd.mainError')}
            //injectedErrors={this.state.injectedErrors}
          />
          <span className="signin__link">
            {i18n.t('pages.offline.resetPwd.rdyTo')}
            <Link to={'/offline/'}>{i18n.t('pages.offline.resetPwd.back')}</Link>
          </span>
        </div>
      )
    return (
      <div className="signin">
        <span
          className="signin__msgReset"
          dangerouslySetInnerHTML={{ __html: i18n.t('pages.offline.resetPwd.title') }}
        />
        <Form
          forms={inputs}
          defaultValues={[{ name: 'email', value: this.state.email }]}
          buttonText={i18n.t('pages.offline.resetPwd.buttonText')}
          onValidSubmit={this.onProcessRequest}
          loading={this.state.requesting}
          mainError={this.state.errored}
          mainErrorText={i18n.t('pages.offline.resetPwd.mainError')}
          success={this.state.success}
          successText={i18n.t('pages.offline.resetPwd.successText')}
          resetOnSuccess={true}
          //injectedErrors={this.state.injectedErrors}
        />

        <span className="signin__link">
          {i18n.t('pages.offline.resetPwd.rdyTo')}
          <Link to={'/offline/'}>{i18n.t('pages.offline.resetPwd.back')}</Link>
        </span>
      </div>
    )
  }
  private onRequest = (values: { [key: string]: string }) => {
    this.setState({ requesting: true, errored: false, email: values.email }, async () => {
      try {
        const request: AxiosResponse = await API.post('/public/resetpassword', {
          email: this.state.email,
        })
        this.setState({ requesting: false, step: '2' })
      } catch (error) {
        this.setState({ requesting: false, errored: true })
      }
    })
  }
  private onProcessRequest = (values: { [key: string]: string }) => {
    this.setState({ requesting: true, errored: false }, async () => {
      try {
        const request: AxiosResponse = await API.put('/public/resetpassword', {
          email: this.state.email,
          password: values.password,
          code: values.code,
        })
        this.setState({ requesting: false, success: true })
      } catch (error) {
        this.setState({ requesting: false, errored: true })
      }
    })
  }
  onChangeInput = (name: string, value: string) => {
    //@ts-ignore
    this.setState({ [name]: value })
  }
}

export default connect((store: IStore) => ({
  loading: store.user.isRequesting,
  error: store.user.loginError,
}))(ResetPassword)
