import React, { Component, CSSProperties } from 'react'
import './PictoButton.scss'

interface IProps {
  icon: JSX.Element
  text?: string
  style?: CSSProperties
  onClick: () => void
}

export default class PictoButton extends React.PureComponent<IProps> {
  public render(): JSX.Element {
    return (
      <div className="pictoBtn" style={this.props.style} onClick={this.props.onClick}>
        {this.props.icon}
      </div>
    )
  }
}
