import React from 'react'
import Form from '../../../../components/Form'
import inputs from './step2form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import theme from '../../../../theme'
import API from '../../../../tools/fetch'
import i18n from '../../../../tools/i18n'

interface IProps {
  firstname: string
  dispatch: (arg: { type: string; payload?: any }) => void
  onValidSubmit: (email: string) => void

  lang: 'en' | 'fr'
}

interface IState {
  requesting: boolean
  errored: boolean
  injectedErrors: { [key: string]: string[] }
}

class Register extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      requesting: false,
      errored: false,
      injectedErrors: {},
    }
  }

  public render(): JSX.Element | null {
    return (
      <div>
        <div style={{ marginTop: '-5px', marginBottom: '10px', maxWidth: '300px' }}>
          {i18n.t('pages.offline.register.welcome')}{' '}
          <span style={{ color: theme.colors.primary.regular }}>{this.props.firstname}</span>
          {i18n.t('pages.offline.register.welcome2')}
        </div>
        <Form
          forms={inputs}
          defaultValues={[]}
          buttonText={i18n.t('pages.offline.register.step2btn')}
          onValidSubmit={this.onValidSubmit}
          loading={this.state.requesting}
          mainError={this.state.errored}
          mainErrorText={i18n.t('pages.offline.register.mainError')}
          injectedErrors={this.state.injectedErrors}
          lang={this.props.lang}
        />
      </div>
    )
  }

  private onValidSubmit = async (values: { [key: string]: string }) => {
    this.setState({
      requesting: true,
      errored: false,
      injectedErrors: {},
    })
    try {
      await API.post('/auth/emailChecker', {
        ...values,
        firstname: this.props.firstname,
      })
      this.props.onValidSubmit(values.email)
    } catch (error) {
      if (error.response?.data?.codes && error.response.data.code[0]) {
        if (error.response.data.code[0] === 'ALREADY_REGISTERED') {
          this.setState({
            requesting: false,
            errored: false,
            injectedErrors: { email: [i18n.t('pages.offline.register.emailAlreadyUse')] },
          })
        } else this.setState({ requesting: false, errored: true, injectedErrors: {} })
      } else this.setState({ requesting: false, errored: true, injectedErrors: {} })
    }
  }
}

export default connect()(Register)
