import { takeLatest, put, call, select } from 'redux-saga/effects'
import {
  ACTIONS_FETCH,
  ACTIONS_FETCH_ERROR,
  ACTIONS_FETCH_REQUEST,
  ACTIONS_FETCH_SUCCESS,
  ACTION_SET,
} from './constants'
import { actionsFetchAction, actionSetAction } from './actions'

export default function* actionsWatcher(): IterableIterator<any> {
  yield takeLatest([ACTION_SET], actionSet)
  return yield takeLatest([ACTIONS_FETCH], actionsFetch)
}

function* actionsFetch({ payload }: any): any {
  yield put({ type: ACTIONS_FETCH_REQUEST })
  const data: number[] | false = yield call(actionsFetchAction)
  if (!data) {
    return yield put({
      type: ACTIONS_FETCH_ERROR,
    })
  }
  return yield put({
    type: ACTIONS_FETCH_SUCCESS,
    payload: data,
  })
}

function* actionSet({ payload }: any): any {
  const data: number[] | false = yield call(actionSetAction, payload.actionId)
  if (!data) {
    return false
  }
  return yield put({
    type: ACTIONS_FETCH_SUCCESS,
    payload: data,
  })
}
