import React from 'react'
import { IEpisodeObject } from '../../../../store/programs/reducer'
import imageResizer from '../../../../tools/imageResizer'
import theme from '../../../../theme'
import { IStore } from '../../../../store'
import { connect } from 'react-redux'
import { IViewExtended } from '../../../../store/reader/reducer'
import ItemContainer from './ItemContainer'
import { Link } from 'react-router-dom'

interface IProps {
  episode: IEpisodeObject
  onPress: (episodeId: number, introduction: boolean) => void
  programId: number
  view?: IViewExtended
  index: number
  leftBar?: boolean
  locked?: boolean
  lang: 'fr' | 'en'
}

class EpisodeCard extends React.Component<IProps> {
  public render(): JSX.Element {
    const { episode, index, view, locked } = this.props
    let content = (
      <div
        className={`episodesList__episodeCard ${locked ? '' : 'episodesList__episodeCardUnlocked'}`}
        key={`episode-card-block-sub-${episode.id}`}
      >
        <div>
          <div
            className="episodesList__episodeCardImage"
            style={{
              backgroundImage: `url(${imageResizer(episode.picture, 'small')}`,
            }}
          >
            <div className="episodesList__episodeCardImageCover">
              <div style={{ flex: '1 1 auto' }} />
              <span>{this.formatTimer()}</span>
              <div
                className="episodesList__episodeCardBar"
                style={{
                  width: this.getPercentDuration(),
                }}
              />
            </div>
          </div>
        </div>
        <div className="episodesList__episodeCardTextContainer">
          <span>{episode.title}</span>
        </div>
      </div>
    )
    if (!locked) {
      content = (
        <Link
          to={{
            pathname: '/online/program/' + this.props.programId + '/episode/' + episode.id,
          }}
          style={{ textDecoration: 'none', color: 'white' }}
        >
          {content}
        </Link>
      )
    }
    if (this.props.leftBar)
      return (
        <ItemContainer
          number={index}
          completed={view && view.complete}
          locked={locked}
          key={`episode-card-container-${episode.id}`}
        >
          {content}
        </ItemContainer>
      )
    return content
  }

  private formatTimer = (): string => {
    var minutes = Math.floor(this.props.episode.duration / 60)
    var seconds = this.props.episode.duration - minutes * 60

    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  }

  private getPercentDuration = (): string | number => {
    //if (this.props.view?.complete) return '100%'
    if (!this.props.view) return 0
    return Math.round((this.props.view.currentDuration / this.props.episode.duration) * 100).toString() + '%'
  }

  private onPress = () => {
    if (!this.props.locked) this.props.onPress(this.props.episode.id, this.props.episode.introduction)
  }
}

export default connect((store: IStore, props: IProps) => {
  return {
    view: store.reader.views.find((v) => v.episodeId === props.episode.id),
  }
})(EpisodeCard)
