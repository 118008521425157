import { Router, Switch, Route, Redirect } from 'react-router-dom'
import history from '../tools/history'
import OfflineRouter from './offline/OfflineRouter'
import { connect } from 'react-redux'
import { IStore } from '../store/types'
import React from 'react'
import OnlineRouter from './online/OnlineRouter'
import { getToken, setTokenListener } from '../tools/token'

interface IProps {
  logged: boolean
}
interface IState {
  token: string | undefined | null
}

class RouterRef extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      token: getToken(),
    }
  }
  componentDidMount() {
    setTokenListener(this.updateToken)
  }
  public render(): JSX.Element {
    return (
      <Switch>
        <Route
          path="/offline/"
          render={() =>
            this.state.token !== undefined && this.state.token !== '' ? <Redirect to="/online/" /> : <OfflineRouter />
          }
        />
        <Route
          path="/online/"
          render={() => {
            return this.state.token === undefined || this.state.token === '' ? (
              <Redirect to="/offline/" />
            ) : (
              <OnlineRouter />
            )
          }}
        />
        <Redirect to="/offline/" />
      </Switch>
    )
  }
  private updateToken = (token: string) => {
    this.setState({ token })
  }
}

export default connect((store: IStore) => ({
  logged: store.user.logged,
}))(RouterRef)
