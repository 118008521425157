import React from 'react'
import './SignIn.scss'
import { Link, RouteProps } from 'react-router-dom'
import { LOGIN, LOGIN_ERROR } from '../../../store/user/constants'
import { connect } from 'react-redux'
import { IStore } from '../../../store/types'
import InputText from '../../../components/Inputs/InputText'
import i18n from '../../../tools/i18n'
import Button from '../../../components/Button'
import theme from '../../../theme'

class Login extends React.Component<any, any> {
  constructor(props: RouteProps) {
    super(props)
    this.state = {
      email: '',
      password: '',
    }
    this.props.dispatch({ type: LOGIN_ERROR, payload: '' })
  }

  handleSubmit = async () => {
    const { email, password } = this.state
    this.props.dispatch({ type: LOGIN, payload: { email, password } })
  }

  onChangeInput = (name: string, value: string) => {
    // @ts-ignore
    this.setState({ [name]: value })
  }

  render() {
    return (
      <div className="signin">
        <form onSubmit={this.handleSubmit}>
          <InputText
            value={this.state.email}
            placeholder={i18n.t('pages.offline.login.inputEmail')}
            onChange={this.onChangeInput}
            name={'email'}
          />
          <InputText
            value={this.state.password}
            secureTextEntry={true}
            placeholder={i18n.t('pages.offline.login.inputPwd')}
            onChange={this.onChangeInput}
            containerStyle={{ marginTop: '12px' }}
            name={'password'}
          />
          <Button
            onClick={this.handleSubmit}
            theme={'primary'}
            locked={this.state.email === '' || this.state.password === ''}
            loading={this.props.loading}
          >
            {i18n.t('pages.offline.login.loginBtn')}
          </Button>
        </form>
        {this.props.error !== '' && (
          <div
            style={{
              color: theme.colors.error,
              marginTop: '10px',
            }}
          >
            {i18n.t(`errors.${this.props.error}`)}
          </div>
        )}
        <span className="signin__link">
          <Link to={'/offline/reset'}>{i18n.t('pages.offline.login.resetPwd')}</Link>
        </span>
        <span className="signin__link">
          {i18n.t('pages.offline.login.lineCreate')}{' '}
          <Link to={'/offline/register'}>{i18n.t('pages.offline.login.createAction')}</Link>
        </span>
      </div>
    )
  }
}

export default connect((store: IStore) => ({
  loading: store.user.isRequesting,
  error: store.user.loginError,
}))(Login)
