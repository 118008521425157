import React from 'react'
import { IEpisodeObject, IProgramObject } from '../../../store/programs/reducer'
import './Header.scss'
import imageResizer from '../../../tools/imageResizer'
import { getCurrentEpisodeToPlay, isPlayableProgram } from '../../../store/programs/tools'
import Button from '../../../components/Button'
import { ArrowLeft, ExternalLink, Play } from 'react-feather'
import theme from '../../../theme'
import { IViewExtended } from '../../../store/reader/reducer'

import intercom from '../../../tools/intercom'
import i18n from '../../../tools/i18n'

interface IProps {
  program: IProgramObject
  views: IViewExtended[]
  history: any
}

class Header extends React.Component<IProps, any> {
  render() {
    const { program } = this.props
    const playable: boolean = isPlayableProgram(this.props.program)
    return (
      <div
        className="headerProgram"
        style={{ backgroundImage: `url(${imageResizer(program.picture, 'homecaroussel')})` }}
      >
        <div className="headerProgram__blackFilter">
          <div className="headerProgram__spacer">
            <Button
              onClick={() => {
                this.props.history.push('/online/')
              }}
              theme={'grey'}
              style={{ width: '38px', padding: '10px 0', marginLeft: '15px' }}
            >
              <ArrowLeft size={18} color={theme.colors.white} />
            </Button>
          </div>
          <div className="headerProgram__infos">
            {program.horsserie && <div className="programcard__titletag">{i18n.t('pages.online.program.hs')}</div>}

            {program.imageTitle && program.imageTitle !== '' ? (
              <div className="headerProgram__title">
                <img src={program.imageTitle} style={{ height: '160px', maxWidth: '320px', objectFit: 'contain' }} />
              </div>
            ) : (
              <div className="headerProgram__title">
                <span>{program.title.split('$')[0]?.toUpperCase() || program.title}</span>
                {program.title.split('$')[1]?.toUpperCase() || ''}
              </div>
            )}
            <div className="headerProgram__subtitle"> {program.titlePicture}</div>
          </div>
          <div className="headerProgram__actionsProgram">
            {playable ? (
              <Button onClick={this.playNext} theme={'primary'}>
                <Play size={18} color={theme.colors.greys.regular} style={{ marginRight: '10px' }} />
                {i18n.t('pages.online.program.start')}
              </Button>
            ) : program.buyUrl !== 'NONE' ? (
              <Button onClick={this.buyAction} theme={'primary'}>
                <ExternalLink size={18} color={theme.colors.greys.regular} style={{ marginRight: '10px' }} />
                {i18n.t('pages.online.program.buy')}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  intercom.showNewMessage(
                    i18n.t('pages.online.program.getIntercom', {
                      type: program.horsserie ? i18n.t('pages.online.program.hs') : '',
                      title: program.title.replace('$', ''),
                    }),
                  )
                }}
                theme={'primary'}
              >
                <ExternalLink size={18} color={theme.colors.greys.regular} style={{ marginRight: '10px' }} />
                {i18n.t('pages.online.program.get')}
              </Button>
            )}
            {!playable && (
              <Button onClick={this.onTrailerRead} theme={'grey'} style={{ marginLeft: '20px' }}>
                <Play size={18} color={theme.colors.white} style={{ marginRight: '10px' }} />{' '}
                {i18n.t('pages.online.program.viewBA')}
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  }
  private onTrailerRead = (): void => {
    let trailer: IEpisodeObject | undefined = this.props.program?.episodes.find((e) => e.trailer)
    if (trailer) this.props.history.push('/online/program/' + this.props.program?.id + '/episode/' + trailer.id)
  }

  private playNext = () => {
    if (this.props.program) {
      const episode = getCurrentEpisodeToPlay(this.props.program, this.props.views)
      if (!episode)
        this.props.history.push(
          '/online/program/' + this.props.program?.id + '/episode/' + this.props.program.episodes[1].id,
        )
      else this.props.history.push('/online/program/' + this.props.program?.id + '/episode/' + episode?.id)
    }
  }

  private buyAction = () => {
    window.open((this.props.program && this.props.program.buyUrl) || '', '_blank')
  }
}

export default Header
