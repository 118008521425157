import React from 'react'
import GreenLight from '../../../../components/Texts/GreenLight'
import { IEpisodeObject, IProgramObject } from '../../../../store/programs/reducer'
import { isProgramCompleted } from '../../../../store/programs/tools'
import { IViewExtended } from '../../../../store/reader/reducer'
import i18n from '../../../../tools/i18n'
import CertificationCard from './CertificationCard'
import EpisodeCard from './EpisodeCard'
import './EpisodesList.scss'
import ItemContainer from './ItemContainer'

interface IProps {
  episodes: IEpisodeObject[]
  views: IViewExtended[]
  locked: boolean
  program: IProgramObject
  lang: 'fr' | 'en'
  onPressEpisode: (episodeId: number, introduction: boolean) => void
}

interface IState {
  orderedEpisodes: IOrderedEpisodes[]
  bonuses: IOrderedEpisodes[]
}

interface IOrderedEpisodes {
  category: string
  episodes: IEpisodeObject[]
}
class EpisodesList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      orderedEpisodes: [],
      bonuses: [],
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.lang !== this.props.lang) {
      this.orderEpisodes()
    }
  }
  orderEpisodes() {
    let ordered: IOrderedEpisodes[] = []
    let bonusOrdered: IOrderedEpisodes[] = []
    this.props.episodes.forEach((e) => {
      if (e.chapter.toLowerCase() === 'bonus') {
        if (bonusOrdered.length === 0) {
          let newArr: IEpisodeObject[] = []
          newArr.push(e)
          bonusOrdered.push({
            category: 'Bonus',
            episodes: newArr,
          })
        } else {
          let newArr: IEpisodeObject[] = bonusOrdered[0].episodes
          newArr.push(e)
          bonusOrdered[0].episodes = newArr
        }
      } else if (e.introduction) {
        let newArr: IEpisodeObject[] = []
        newArr.push(e)
        ordered.push({
          category: i18n.t('pages.online.program.intro'),
          episodes: newArr,
        })
      } else if (e.trailer) {
      } else if (!ordered.find((o) => o.category === e.chapter)) {
        let newArr: IEpisodeObject[] = []
        newArr.push(e)
        ordered.push({
          category: e.chapter,
          episodes: newArr,
        })
      } else {
        ordered = ordered.map((o) => {
          if (o.category !== e.chapter) return o
          let newArr: IEpisodeObject[] = o.episodes
          newArr.push(e)
          return {
            category: o.category,
            episodes: newArr,
          }
        })
      }
    })
    this.setState({ orderedEpisodes: ordered, bonuses: bonusOrdered })
  }
  componentDidMount() {
    this.orderEpisodes()
  }
  render() {
    const { program, views } = this.props
    let startIndex: number = 0
    return (
      <div className="episodesList">
        {this.state.orderedEpisodes.map((oe) => {
          let index: number = startIndex
          startIndex += oe.episodes.length

          return (
            <div key={`episode-list-container-category-${oe.category}`}>
              {oe.category !== 'introduction' && (
                <ItemContainer>
                  <GreenLight text={oe.category} />
                </ItemContainer>
              )}
              <div>
                {oe.episodes.map((e) => {
                  index++
                  return this.generateCard(e, index)
                })}
              </div>
            </div>
          )
        })}

        {this.props.program.certification !== 'NONE' && (
          <CertificationCard locked={!isProgramCompleted(program, views)} />
        )}
        {this.state.bonuses.map((oe) => {
          let index: number = startIndex
          startIndex += oe.episodes.length

          return (
            <div key={`episode-list-container-category-${oe.category}`}>
              {oe.category !== 'introduction' && (
                <ItemContainer>
                  <GreenLight text={oe.category} />
                </ItemContainer>
              )}
              <div>
                {oe.episodes.map((e) => {
                  index++
                  return this.generateCard(e, index)
                })}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  private generateCard(episode: IEpisodeObject, index: number): JSX.Element {
    return (
      <EpisodeCard
        episode={episode}
        onPress={this.props.onPressEpisode}
        index={index}
        leftBar={true}
        lang={this.props.lang}
        locked={this.props.locked && !episode.introduction && !episode.free}
        key={`program-episode-episode-card-${episode.id}`}
        programId={this.props.program.id}
      />
    )
  }
}

export default EpisodesList
