import i18n from 'i18n-js'
import locales from './locales'
import Cookies from 'universal-cookie'
const cookies = new Cookies()
let clang = cookies.get('lang')
let lang = navigator.language
if (lang !== 'fr' && lang !== 'en') {
  lang = 'fr'
}
if (clang === 'fr' || clang === 'en') {
  lang = clang
} else {
  cookies.set('lang', lang, { path: '/' })
}

i18n.fallbacks = true
i18n.translations = locales
i18n.locale = lang //Localization.locale

const getLang: () => 'en' | 'fr' = () => {
  return cookies.get('lang')
}
const setLang: (langf: 'en' | 'fr') => void = (langf) => {
  cookies.set('lang', langf, { path: '/' })
  i18n.locale = langf
  console.log(langf)
}

export default i18n
export { getLang, setLang }
