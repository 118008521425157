import React from 'react'
import { IStore } from '../../../store'
import { connect } from 'react-redux'
import { IUserObject } from '../../../store/types'
import inputs from './form'
import Form from '../../../components/Form'
import { USER_RESET_ERROR, USER_UPDATE } from '../../../store/user/constants'
import GreenLight from '../../../components/Texts/GreenLight'
import Button from '../../../components/Button'
import { ArrowLeft } from 'react-feather'
import theme from '../../../theme'
import i18n from '../../../tools/i18n'

interface IProps {
  user: IUserObject
  dispatch: (object: { type: string; payload?: any }) => void
  navigation: any
  isRequesting: boolean
  error: boolean
  success: boolean
  history: any
}
interface IState {}
class Settings extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    this.props.dispatch({ type: USER_RESET_ERROR })
  }
  public render(): JSX.Element | null {
    const { user } = this.props
    let df: any = [
      {
        name: 'firstname',
        value: user.firstname,
      },
      {
        name: 'lastname',
        value: user.lastname,
      },
      {
        name: 'companyName',
        value: user.company?.name || '',
      },
      {
        name: 'email',
        value: user.email,
      },
    ]
    return (
      <div
        style={{
          padding: '20px',
          maxWidth: ' 600px',
          display: 'flex',
          flexDirection: 'column',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        <Button
          onClick={() => {
            this.props.history.push('/online/')
          }}
          theme={'grey'}
          style={{ width: '38px', padding: '10px 0' }}
        >
          <ArrowLeft size={18} color={theme.colors.white} />
        </Button>
        <GreenLight text={i18n.t('pages.online.settings.title')} />
        <Form
          forms={inputs}
          defaultValues={df}
          buttonText={i18n.t('pages.online.settings.btn')}
          onValidSubmit={this.onValidSubmit}
          loading={this.props.isRequesting}
          mainError={this.props.error}
          mainErrorText={i18n.t('pages.online.settings.mainError')}
          success={this.props.success}
          successText={i18n.t('pages.online.settings.success')}
        />
      </div>
    )
  }

  private onValidSubmit = (values: { [key: string]: string }) => {
    this.props.dispatch({ type: USER_UPDATE, payload: values })
  }
}

export default connect((store: IStore) => ({
  user: store.user.data,
  isRequesting: store.user.isRequesting,
  error: store.user.error,
  success: store.user.success,
}))(Settings)
