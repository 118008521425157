import React from 'react'
import { Check, ThumbsUp } from 'react-feather'
import { connect } from 'react-redux'
import { IStore } from '../../store'
import { ACTION_SET } from '../../store/actions/constants'
import i18n from '../../tools/i18n'
import Button from '../Button'
import './ActionCard.scss'

interface IProps {
  text: string
  subtext: string
  id: number
  actionIds: number[]
  dispatch: (object: { type: string; payload?: any }) => void
}

class ActionCard extends React.Component<IProps> {
  public render(): JSX.Element {
    return (
      <div className="actionCard">
        <div className="actionCard__textContainer">
          <div className="actionCard__text">{this.props.text}</div>
          <div className="actionCard__subtext">{this.props.subtext}</div>

          {this.props.actionIds.includes(this.props.id) ? (
            <div className="actionCard__actionTryed">
              <Check size={18} />
              {i18n.t('pages.components.actionCard.tryed')}
            </div>
          ) : (
            <div className="actionCard__actionTryed">
              <Button onClick={this.onPress} style={{ width: '130px' }}>
                <ThumbsUp size={18} style={{ marginRight: '7px' }} />
                {i18n.t('pages.components.actionCard.willtry')}
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }
  private onPress = () => {
    this.props.dispatch({ type: ACTION_SET, payload: { actionId: this.props.id } })
  }
}

export default connect((store: IStore, props: IProps) => {
  return {
    actionIds: store.actions.actionIds,
  }
})(ActionCard)
