import React, { ChangeEvent, CSSProperties } from 'react'
import { Eye, EyeOff } from 'react-feather'
import theme from '../../theme'

interface IProps {
  value: string
  onChange: (name: string, value: string) => void
  name: string
  secureTextEntry?: boolean
  style?: CSSProperties
  containerStyle?: CSSProperties
  placeholder?: string
  locked?: boolean
  lang?: 'fr' | 'en'
}
interface IState {
  secure: boolean
}
export default class InputText extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { secure: true }
  }

  public render(): JSX.Element {
    const { placeholder, value, secureTextEntry, style, containerStyle, locked } = this.props
    return (
      <div style={{ ...styles.container, ...containerStyle }}>
        {locked ? (
          <span style={{ ...styles.input, ...{ paddingTop: '5px', color: `${theme.colors.white}50` }, ...style }}>
            {value}
          </span>
        ) : (
          <input
            placeholder={placeholder}
            onChange={this.onChange}
            value={value}
            type={secureTextEntry && this.state.secure ? 'password' : 'text'}
            style={{ ...styles.input, ...style }}
          />
        )}
        {secureTextEntry && (
          <div style={styles.icon} onClick={this.onToggleSecure}>
            {this.state.secure ? (
              <Eye color={theme.colors.white} size={18} />
            ) : (
              <EyeOff color={theme.colors.white} size={18} />
            )}
          </div>
        )}
      </div>
    )
  }
  onToggleSecure = () => {
    this.setState({ secure: !this.state.secure })
  }
  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(this.props.name, event.target.value)
  }
}

const styles: { container: CSSProperties; input: CSSProperties; icon: CSSProperties } = {
  container: {
    height: '28px',
    backgroundColor: theme.colors.greys.light,
    borderRadius: '3px',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
    flexDirection: 'row',
    display: 'flex',
  },
  input: {
    flex: '1 1 auto',
    marginRight: '8px',
    color: theme.colors.white,
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '15px',
    lineHeight: '18px',
    outline: '0 !important',
  },
  icon: {
    padding: '5px',
    cursor: 'pointer',
  },
}
