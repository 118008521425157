import { IFormObject } from '../../../components/Form'
import i18n from '../../../tools/i18n'

const inputs: IFormObject[] = [
  {
    name: 'actualPassword',
    title: 'pages.online.password.form.actualPwd',
    type: 'password',
    half: false,
    validation: { presence: { allowEmpty: false, message: 'NONE' } },
  },
  {
    name: 'newPassword',
    title: 'pages.online.password.form.newPwd',
    type: 'password',
    half: false,
    validation: {
      presence: { allowEmpty: false, message: 'NONE' },
      /*regex: {
        pattern: '(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])',
        message: 'Must contain a capital, lowercase, and number',
      },*/
      length: {
        minimum: 8,
        message: 'pages.online.password.form.newPwdError',
      },
    },
  },
  {
    name: 'newPasswordBis',
    title: 'pages.online.password.form.newPwdBis',
    type: 'password',
    half: false,
    validation: function (value: any, attributes: any, attributeName: any, options: any, constraints: any) {
      if (attributes.newPassword !== '' && attributes.newPassword !== value)
        return {
          length: {
            minimum: 100000000000000,
            message: 'pages.online.password.form.newPwdBisError',
          },
        }
      return null
    },
  },
]

export default inputs
