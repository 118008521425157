import React, { CSSProperties } from 'react'
import './GreyUpScale.scss'

interface IProps {
  style?: CSSProperties
  text: string
}

export default class GreyUpSpace extends React.PureComponent<IProps> {
  public render(): JSX.Element {
    return (
      <div className="greyUpScale" style={this.props.style}>
        <span>{this.props.text.toUpperCase()}</span>
      </div>
    )
  }
}
