import React, { CSSProperties } from 'react'
import './Separator.scss'

interface IProps {
  style?: CSSProperties
  text?: string
}

export default class Separator extends React.PureComponent<IProps> {
  public render(): JSX.Element {
    return (
      <div className="separator" style={this.props.style}>
        <div className="separator__line" />
        {this.props.text && <span className="separator__text">{this.props.text}</span>}
        <div className="separator__line" />
      </div>
    )
  }
}
