import { IFormObject } from '../../../components/Form'
import i18n from '../../../tools/i18n'

const inputs: IFormObject[] = [
  {
    name: 'email',
    title: 'pages.online.settings.fields.email',
    type: 'text',
    half: false,
    locked: true,
    validation: { presence: { allowEmpty: false }, email: true },
  },
  {
    name: 'firstname',
    title: 'pages.online.settings.fields.firstname',
    type: 'text',
    half: true,
    validation: { presence: { allowEmpty: false, message: 'NONE' } },
  },
  {
    name: 'lastname',
    title: 'pages.online.settings.fields.lastname',
    type: 'text',
    half: true,
    validation: { presence: { allowEmpty: false, message: 'NONE' } },
  },
  {
    name: 'companyName',
    title: 'pages.online.settings.fields.companyName',
    type: 'text',
    half: false,
    validation: { presence: { allowEmpty: false, message: 'NONE' } },
  },
]

export default inputs
