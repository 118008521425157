import React, { CSSProperties } from 'react'
import './WhiteIcon.scss'

interface IProps {
  style?: CSSProperties
  text: string
  icon: JSX.Element
}

export default class WhiteIcon extends React.PureComponent<IProps> {
  public render(): JSX.Element {
    return (
      <div className="whiteIcon">
        {this.props.icon}
        <span style={this.props.style}>{this.props.text}</span>
      </div>
    )
  }
}
