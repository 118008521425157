export const APP_IN_BACKGROUND: string = 'PAGE_PRINT_NOTIFICATION'

export const APP_RUNNING: string = 'APP_RUNNING'
export const APP_RUNNING_TRACK_FAIL: string = 'APP_RUNNING_TRACK_FAIL'
export const APP_RUNNING_TRACK_SUCCESS: string = 'APP_RUNNING_TRACK_SUCCESS'
export const TRACKER_SET_PROGRAM: string = 'TRACKER_SET_PROGRAM'
export const USER_IS_PREMIUM: string = 'USER_IS_PREMIUM'

export const OPENNING_RESSOURCE: string = 'OPENNING_RESSOURCE'
export const USER_JUST_REGISTERED: string = 'USER_JUST_REGISTERED'

export const APP_OPENED: string = 'APP_OPENED'
export const PROGRAM_OPENED: string = 'PROGRAM_OPENED'
export const EPISODE_OPENED: string = 'EPISODE_OPENED'
export const EPISODE_READING: string = 'EPISODE_READING'
export const EPISODE_ENDED: string = 'EPISODE_ENDED'
export const PROGRAM_ENDED: string = 'PROGRAM_ENDED'
