import React, { CSSProperties } from 'react'
import { Download, Lock } from 'react-feather'
import './SmallImageCard.scss'

interface IProps {
  image: string
  text: string
  subtext: string
  actionType?: 'download'
  actionLink?: string
  onLinkClick?: (link: string) => void
  locked?: boolean
  highLighted?: boolean
}

export default class SmallImageCard extends React.Component<IProps> {
  public render(): JSX.Element {
    return (
      <div className={`smallImageCard ${this.props.highLighted ? ' smallImageCardHL' : ''}`}>
        <div className="smallImageCard__imgContainer">
          <img src={this.props.image} key={this.props.image} />
        </div>
        <div className="smallImageCard__textContainer">
          <div className="smallImageCard__text">{this.props.text}</div>
          <div className="smallImageCard__subtext">{this.props.subtext}</div>
        </div>
        {this.props.actionType && (
          <div className="smallImageCard__actionContainer">
            {this.props.locked ? (
              <a href="#">
                <Lock />
              </a>
            ) : (
              <a href={this.props.actionLink} target="_blank" onClick={this.onLinkClick}>
                {this.props.actionType === 'download' && <Download />}{' '}
              </a>
            )}
          </div>
        )}
      </div>
    )
  }
  private onLinkClick = () => {
    this.props.onLinkClick && this.props.onLinkClick(this.props.actionLink || '')
    return true
  }
}
