import React, { CSSProperties } from 'react'
import { Download } from 'react-feather'
import { IMentorObject, IProgramObject } from '../../store/programs/reducer'
import imageResizer from '../../tools/imageResizer'
import './SmallMentorCard.scss'

interface IProps {
  mentor: IMentorObject
  program: IProgramObject
  onClick: (mentor: IMentorObject, program: IProgramObject) => void
}

export default class SmallMentorCard extends React.Component<IProps> {
  public render(): JSX.Element {
    const { mentor } = this.props
    return (
      <div className="smallMentorCard" onClick={this.onClick}>
        <div className="smallMentorCard__imgContainer">
          <img src={imageResizer(mentor.picture || '', 'medium')} key={imageResizer(mentor.picture || '', 'medium')} />
        </div>
        <div className="smallMentorCard__textContainer">
          <div className="smallMentorCard__text">
            {mentor.firstname} {mentor.lastname}
          </div>
          <div className="smallMentorCard__subtext">{mentor.description}</div>
        </div>
      </div>
    )
  }
  private onClick = () => this.props.onClick(this.props.mentor, this.props.program)
}
