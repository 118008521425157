import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/images/logo_fasterclass.png'
import './Header.scss'
import { User } from 'react-feather'
import NavMenu from './NavMenu'
import Popup from 'reactjs-popup'
import { IPremiumCompanyObject } from '../../store/user/reducer'
import imageResizer from '../../tools/imageResizer'
import i18n from '../../tools/i18n'
import LangPicker from '../LangPicker'

interface IProps {
  firstname: string
  premiumCompany: IPremiumCompanyObject | undefined
  processLogout: () => void
  lang: 'en' | 'fr'
  toggleLang: (lang: 'en' | 'fr') => void
}
interface IState {
  menuOpened: boolean
  sponsorOpened: boolean
}

class Header extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = { menuOpened: false, sponsorOpened: false }
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.closeMenu, true)
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.closeMenu, true)
  }
  private closeMenu = () => {
    if (this.state.menuOpened || this.state.sponsorOpened) {
      setTimeout(() => this.setState({ menuOpened: false, sponsorOpened: false }), 200)
    }
  }
  public render(): JSX.Element {
    const { premiumCompany } = this.props
    return (
      <div className="header">
        <div className="header__container">
          <Link to="/online/" className="header__logo-box">
            <img src={Logo} alt="logo" className="header__logo" />
          </Link>

          <div className="header__options">
            <div className="header__options-secondary">
              {i18n.t('pages.online.header.hello', { name: this.props.firstname })}
            </div>

            <div className="header__usermenu" onClick={this.toggleMenu}>
              <User color="white" size={24} />
            </div>
            <Popup open={this.state.menuOpened} modal={true} position="top right" contentStyle={{ position: 'unset' }}>
              <NavMenu processLogout={this.props.processLogout} />
            </Popup>
            {premiumCompany && (
              <div className="header__premiumCompany" onClick={this.toggleSponsor}>
                <div className="header__premiumCompanyText">
                  <div className="header__premiumCompanyText1">{i18n.t('pages.online.header.preniumT1')}</div>
                  <div className="header__premiumCompanyText2">{i18n.t('pages.online.header.preniumT2')}</div>
                </div>
                <div className="header__premiumCompanyLogo">
                  <img src={imageResizer(premiumCompany.logo, 'medium')} alt="" />
                </div>

                <Popup
                  open={this.state.sponsorOpened}
                  modal={true}
                  position="top right"
                  contentStyle={{ position: 'unset' }}
                >
                  <div className="header__sponsorPopup">
                    {i18n.t('pages.online.header.sponsorInfor', { company: premiumCompany.name })}{' '}
                    <a href={i18n.t('pages.online.header.sponsorInfoUrl')} target="_blank" rel="noreferrer">
                      {i18n.t('pages.online.header.sponsorInfoBtn')}
                    </a>
                  </div>
                </Popup>
              </div>
            )}
            <LangPicker lang={this.props.lang} toggleLang={this.props.toggleLang} />
          </div>
        </div>
      </div>
    )
  }
  private toggleMenu = () => {
    this.setState({ menuOpened: !this.state.menuOpened })
  }
  private toggleSponsor = () => {
    this.setState({ sponsorOpened: !this.state.sponsorOpened })
  }
}

export default Header
