import React from 'react'
import { Link } from 'react-router-dom'
import './NavMenu.scss'
import { Settings, LogOut, Lock, MessageSquare, Tag, HelpCircle, AlertOctagon, ThumbsUp } from 'react-feather'
import theme from '../../theme'
import GreyUpSpace from '../Texts/GreyUpScale'
import intercom from '../../tools/intercom'
import i18n from '../../tools/i18n'

interface IProps {
  processLogout: () => void
}

class NavMenu extends React.Component<IProps> {
  public render(): JSX.Element {
    return (
      <div className="overlay">
        <div className="overlay__content">
          <GreyUpSpace text={i18n.t('pages.online.header.menu.you')} />
          <Link className="overlay__option" to="/online/actions">
            <ThumbsUp color={theme.colors.white} size={18} />
            <span>{i18n.t('pages.online.header.menu.actions')}</span>
          </Link>
          <Link className="overlay__option" to="/online/settings">
            <Settings color={theme.colors.white} size={18} />
            <span>{i18n.t('pages.online.header.menu.settings')}</span>
          </Link>
          <Link className="overlay__option" to="/online/password">
            <Lock color={theme.colors.white} size={18} />
            <span>{i18n.t('pages.online.header.menu.password')}</span>
          </Link>

          <div className="overlay__option overlay__logout" onClick={this.props.processLogout}>
            <LogOut color={theme.colors.error} size={18} />
            <span>{i18n.t('pages.online.header.menu.logout')}</span>
          </div>

          <GreyUpSpace text={i18n.t('pages.online.header.menu.contribute')} />

          <div
            className="overlay__option"
            onClick={() => intercom.showNewMessage(i18n.t('pages.online.header.menu.suggestText'))}
          >
            <MessageSquare color={theme.colors.white} size={18} />
            <span>{i18n.t('pages.online.header.menu.suggest')}</span>
          </div>
          <div
            className="overlay__option"
            onClick={() => intercom.showNewMessage(i18n.t('pages.online.header.menu.subjectText'))}
          >
            <Tag color={theme.colors.white} size={18} />
            <span>{i18n.t('pages.online.header.menu.subject')}</span>
          </div>
          <div
            className="overlay__option"
            onClick={() => intercom.showNewMessage(i18n.t('pages.online.header.menu.helpText'))}
          >
            <HelpCircle color={theme.colors.white} size={18} />
            <span>{i18n.t('pages.online.header.menu.help')}</span>
          </div>
          <div
            className="overlay__option"
            onClick={() => intercom.showNewMessage(i18n.t('pages.online.header.menu.bugText'))}
          >
            <AlertOctagon color={theme.colors.white} size={18} />
            <span>{i18n.t('pages.online.header.menu.bug')}</span>
          </div>
        </div>
      </div>
    )
  }
}

export default NavMenu
