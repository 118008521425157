import React from 'react'
import { User } from 'react-feather'
import Button from '../../../components/Button'
import { IUserObject } from '../../../store/types'
import theme from '../../../theme'
import i18n from '../../../tools/i18n'

interface IProps {
  user: IUserObject
  loadingPayment: boolean
  paymentErrors: string[]
  onChangeAccount: () => void
  onLoad: () => void
  onPayment: () => void
}

class ProgramDisplay extends React.Component<IProps> {
  componentDidMount() {
    this.props.onLoad()
  }
  public render(): JSX.Element {
    const { user } = this.props
    return (
      <div>
        <div style={{ width: '300px', marginBottom: '35px' }}>{i18n.t('pages.checkout.loggedIn.title')}</div>
        <div
          style={{
            fontWeight: 100,
            fontSize: '16px',
            marginBottom: '10px',
          }}
        >
          {i18n.t('pages.checkout.loggedIn.text1')}{' '}
        </div>
        <div style={{ display: 'flex', marginTop: '10px', marginBottom: '45px' }}>
          <div
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: theme.colors.greys.light,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '10px',
            }}
          >
            <User color={theme.colors.white} size={24} />
          </div>
          <div>
            <div>
              {user.firstname} {user.lastname}
            </div>
            <div style={{ color: `${theme.colors.white}80`, fontWeight: 300 }}>{user.email}</div>
          </div>
        </div>
        <div style={{ color: theme.colors.error, maxWidth: '300px', marginTop: '-10px' }}>{this.getErrors()}</div>
        <Button theme={'primary'} onClick={this.props.onPayment} loading={this.props.loadingPayment}>
          {i18n.t('pages.checkout.loggedIn.btn1')}
        </Button>
        <Button theme={'greyLight'} onClick={this.props.onChangeAccount}>
          {i18n.t('pages.checkout.loggedIn.btn2')}
        </Button>
      </div>
    )
  }
  private getErrors = () => {
    let errorsFormated: string[] = this.props.paymentErrors.map((er) => {
      switch (er) {
        case 'PROGRAM_NOT_FOUND':
          return i18n.t('pages.checkout.loggedIn.errors.PROGRAM_NOT_FOUND')
        case 'PROGRAM_OWNED':
          return i18n.t('pages.checkout.loggedIn.errors.PROGRAM_OWNED')
        case 'ONE_OF_PROGRAM_OWNED':
          return i18n.t('pages.checkout.loggedIn.errors.ONE_OF_PROGRAM_OWNED')
        default:
          return i18n.t('pages.checkout.loggedIn.errors.default')
      }
    })
    return errorsFormated.concat(' ')
  }
}

export default ProgramDisplay
