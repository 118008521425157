import { USER_LOGOUT } from '../user/constants'
import { APP_RUNNING_TRACK_FAIL, APP_RUNNING_TRACK_SUCCESS, TRACKER_SET_PROGRAM } from './constants'

export interface ITrackerState {
  count: number
  currentProgramId?: number
}

const initialState: ITrackerState = {
  count: 1,
  currentProgramId: undefined,
}

export default (
  state: ITrackerState = initialState,
  { type, payload }: { type: string; payload: any },
): ITrackerState => {
  switch (type) {
    case TRACKER_SET_PROGRAM:
      return {
        ...state,
        currentProgramId: payload.programId,
      }
    case APP_RUNNING_TRACK_FAIL:
      return {
        ...state,
        count: state.count + 1,
      }
    case APP_RUNNING_TRACK_SUCCESS:
      return {
        ...state,
        count: 1,
      }
    case USER_LOGOUT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
