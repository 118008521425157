import React from 'react'
import { connect } from 'react-redux'
import { IStore } from '../../../store/types'
import { IViewExtended } from '../../../store/reader/reducer'
import { IMentorObject, IProgramObject } from '../../../store/programs/reducer'
import {
  getNBFasterclass,
  getNBRessources,
  isPlayableProgram,
  isProgramCompleted,
  programTranslate,
} from '../../../store/programs/tools'
import Header from './Header'
import './ProgramDetails.scss'
import GreyUpSpace from '../../../components/Texts/GreyUpScale'
import SmallImageCard from '../../../components/SmallImageCard'
import imageResizer from '../../../tools/imageResizer'
import WhiteIcon from '../../../components/Texts/WhiteIcon'
import { Award, FileText, Film, Video } from 'react-feather'
import theme from '../../../theme'
import EpisodesList from './EpisodesList'
import { PROGRAM_OPENED } from '../../../store/trackEvent/constants'
import { MENTOR_POPUP_OPEN } from '../../../store/mentorPopup/constants'
import SmallMentorCard from '../../../components/SmallMentorCard'
import SmallImageButtonCard from '../../../components/SmallImageButtonCard'
import i18n from '../../../tools/i18n'

import frFlag from '../../../assets/images/france-flag-icon-32.png'
import enFlag from '../../../assets/images/united-kingdom-flag-icon-64.png'
import { USER_LANG_CHANGE } from '../../../store/user/constants'

interface IProps {
  match: any
  views: IViewExtended[]
  program: IProgramObject | undefined
  lang: 'fr' | 'en'
  history: any
  dispatch: (object: { type: string; payload?: any }) => void
  isProgramCompleted: boolean
}

class ProgramDetails extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props)
  }
  componentDidMount() {}
  public render(): JSX.Element {
    const { program, history, views, lang } = this.props
    if (!program) return <div></div>
    return (
      <div className="programDetails">
        <Header program={program} history={history} views={views} />

        <div className="programDetails__descriptionContainer">
          <div className="programDetails__description">
            <div className="programDetails__descriptionText">{program.description}</div>
            <GreyUpSpace text={i18n.t('pages.online.program.languages')} />
            <div className="programDetails__languages">
              <div
                onClick={() => {
                  this.toggleLang('fr')
                }}
                className={lang === 'fr' ? 'programDetails__langselected' : 'programDetails__langnotselected'}
              >
                <img src={frFlag} />
              </div>
              {program.enReady && (
                <div
                  onClick={() => {
                    this.toggleLang('en')
                  }}
                  className={lang === 'en' ? 'programDetails__langselected' : 'programDetails__langnotselected'}
                >
                  <img src={enFlag} />
                </div>
              )}
            </div>
            <GreyUpSpace text={i18n.t('pages.online.program.by')} />
            <SmallMentorCard mentor={program.mentor} program={program} onClick={this.onMentorClick} />
            <GreyUpSpace text={i18n.t('pages.online.program.include')} />
            <WhiteIcon
              text={i18n.t('pages.online.program.xFastercl', { count: getNBFasterclass(this.props.program) })}
              icon={<Film color={theme.colors.white} size={16} />}
            />
            {getNBRessources(program) !== '0' && (
              <WhiteIcon
                text={i18n.t('pages.online.program.xRessources', { count: getNBRessources(this.props.program) })}
                icon={<FileText color={theme.colors.white} size={16} />}
              />
            )}

            {program.certification !== 'NONE' && (
              <WhiteIcon
                text={i18n.t('pages.online.program.certificate')}
                icon={<Award color={theme.colors.white} size={16} />}
              />
            )}
            <GreyUpSpace text={i18n.t('pages.online.program.teachers')} />
            <div className="programDetails__mentorsContainer">
              {this.getMentorsUnique().map((mentor) => {
                return (
                  <div
                    key={`mentor-grid-${mentor.id}`}
                    className="programDetails__mentorCard"
                    style={{ backgroundImage: `url(${imageResizer(mentor.picture, 'mentorslider')})` }}
                    onClick={() => {
                      this.onMentorClick(mentor, program)
                    }}
                  >
                    <div className="programDetails__mentorCardBlackLayer">
                      <div className="programDetails__mentorCardSpacer" />
                      <div className="programDetails__mentorCardText">
                        {mentor.firstname} {mentor.lastname}
                      </div>
                      <div className="programDetails__mentorCardSubtext">{mentor.description}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="programDetails__episodesList">
            {this.props.program && this.props.program.classbookUrl && this.props.program.classbookImg && (
              <SmallImageButtonCard
                image={imageResizer(program.classbookImg || '', 'smallCB')}
                text={i18n.t('pages.online.program.classbook')}
                subtext={i18n.t('pages.online.program.classbooksub')}
                key={`classbook-card-${program.id}`}
                actionType={'download'}
                actionLink={program.classbookUrl}
                locked={!isPlayableProgram(this.props.program)}
                highLighted={true}
              />
            )}
            <GreyUpSpace text={i18n.t('pages.online.program.episodes')} />
            <EpisodesList
              episodes={program.episodes}
              views={this.props.views}
              onPressEpisode={this.onPressEpisode}
              locked={!isPlayableProgram(this.props.program)}
              program={program}
              lang={this.props.lang}
            />
          </div>
        </div>
      </div>
    )
  }

  private toggleLang = (lang: 'en' | 'fr') => {
    if (lang !== this.props.lang) this.props.dispatch({ type: USER_LANG_CHANGE, payload: { lang } })
  }

  private onMentorClick = (mentor: IMentorObject, program: IProgramObject) => {
    this.props.dispatch({ type: MENTOR_POPUP_OPEN, payload: { mentorId: mentor.id, programId: program.id } })
  }
  private getMentorsUnique = (): IMentorObject[] => {
    let mentors: IMentorObject[] = []
    if (!this.props.program) return mentors
    let mentorsId: any = {}
    this.props.program.episodes.forEach((ep) => {
      if (!mentorsId[ep.mentor.id]) {
        mentors.push(ep.mentor)
        mentorsId[ep.mentor.id] = true
      }
    })
    return mentors
  }

  private onPressEpisode = (episodeId: number, introduction: boolean): void => {
    this.props.history.push('/online/episode/' + episodeId)
  }
}
export default connect((store: IStore, props: IProps) => {
  let program = store.programs.program
  program = programTranslate(program, store.user.lang)
  return {
    program,
    views: store.reader.views,
    isProgramCompleted: isProgramCompleted(program, store.reader.views),
    lang: store.user.lang,
  }
})(ProgramDetails)
