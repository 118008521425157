import React from 'react'
import { Award } from 'react-feather'
import theme from '../../../../theme'

import certLogo from '../../../../assets/images/icon_certification.png'
import i18n from '../../../../tools/i18n'

interface IProps {
  locked: boolean
}

export default class CertificationCard extends React.PureComponent<IProps> {
  public render(): JSX.Element {
    const { locked } = this.props
    let content = (
      <div className={'episodesList__episodeCard'}>
        <div className="episodesList__episodeCardImage">
          <img
            className="episodesList__episodeCardImage"
            src={certLogo}
            style={{
              width: 80,
              height: 60,
              borderRadius: 3,
              justifyContent: 'flex-end',
            }}
          />
        </div>

        <div className="episodesList__episodeCardTextContainer">
          <span>{i18n.t('pages.online.program.certifCard.title')}</span>

          {!locked && (
            <div style={{ fontSize: '12px' }}>
              <br />
              {i18n.t('pages.online.program.certifCard.complete')}
            </div>
          )}
        </div>
      </div>
    )
    return (
      <div className="episodesList__itemContainer">
        <div className="episodesList__itemContainerContainer">
          <div
            className="episodesList__itemContainerNumberContainer"
            style={{
              backgroundColor: locked ? `${theme.colors.white}30` : theme.colors.primary.regular,
            }}
          >
            <Award
              size={18}
              color={locked ? theme.colors.white : theme.colors.greys.regular}
              style={{
                textAlign: 'center',
                padding: 6,
              }}
            />
          </div>
          <div className="episodesList__itemContainerBar">
            <div className="episodesList__itemContainerBarBar"></div>
            <div className="episodesList__itemContainerBarFil"></div>
          </div>
        </div>
        <div style={{ flex: 1 }}>{content}</div>
      </div>
    )
  }
}
