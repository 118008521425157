import React from 'react'
import './LangPicker.scss'
import frFlag from './assets/france-flag-icon-32.png'
import enFlag from './assets/united-kingdom-flag-icon-64.png'

interface IProps {
  lang: 'fr' | 'en'
  toggleLang: (lang: 'fr' | 'en') => void
}
interface IState {}

export default class LangPicker extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = {}
  }
  public render(): JSX.Element {
    return (
      <div className="langpicker__container">
        <div
          className={`langpicker__flagContainer langpicker__first ${
            this.props.lang === 'fr' ? 'langpicker__current' : ''
          }`}
          onClick={() => {
            this.toggle('fr')
          }}
        >
          <div className={`${this.props.lang === 'fr' ? 'langpicker__flagFadeOff' : 'langpicker__flagFade'}`}>
            <img src={frFlag} />
          </div>
        </div>
        <div
          className={`langpicker__flagContainer langpicker__second ${
            this.props.lang === 'en' ? 'langpicker__current' : ''
          }`}
          onClick={() => {
            this.toggle('en')
          }}
        >
          <div className={`${this.props.lang === 'en' ? 'langpicker__flagFadeOff' : 'langpicker__flagFade'}`}>
            <img src={enFlag} />
          </div>
        </div>
      </div>
    )
  }
  private toggle(lang: 'fr' | 'en'): void {
    if (this.props.lang !== lang) {
      this.props.toggleLang(lang)
    }
  }
}
