import axios from 'axios'

import config from '../../config'
import { getToken, setToken } from '../token'
import StoreRegistry from '../../store/registry'
import { USER_LOGOUT } from '../../store/user/constants'

const initInterceptorRequest: any = (client: any) => {
  client.interceptors.request.use((requestConfig: any) => {
    const token: string | null = getToken()
    if (token) requestConfig.headers.authorization = 'Bearer ' + token
    return requestConfig
  })
  client.interceptors.response.use(null, (error: any) => {
    if (error.response && error.response.data && error.response.data.code) {
      if (typeof error.response.data.code !== typeof []) {
        error.response.data.codes = [error.response.data.code]
      } else error.response.data.codes = error.response.data.code
      if (error.response.data.codes.includes('NO_OR_WRONG_TOKEN_PROVIDED')) {
        setToken('')
        StoreRegistry.getStore().dispatch({ type: USER_LOGOUT })
      }
    }
    return Promise.reject(error)
  })

  return client
}

const httpClient = initInterceptorRequest(axios.create({ baseURL: config.apiUrl, timeout: 5000 }))

export default httpClient
