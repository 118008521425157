import { IFormObject } from '../../../../components/Form'
import i18n from '../../../../tools/i18n'

const inputs: IFormObject[] = [
  {
    name: 'password',
    title: 'pages.offline.register.fields.password',
    type: 'password',
    half: false,
    validation: {
      presence: { allowEmpty: false, message: 'NONE' },
      /*regex: {
        pattern: '(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])',
        message: 'Must contain a capital, lowercase, and number',
      },*/
      length: {
        minimum: 8,
        message: 'pages.offline.register.fields.passwordError',
      },
    },
    legend: 'pages.offline.register.fields.passwordLegend',
  },
  {
    name: 'cgu',
    title: '',
    type: 'CGU',
    half: false,
    //@ts-ignore
    validation: function (value, attributes, attributeName, options, constraints) {
      if (attributes.cgu !== 'true')
        return {
          length: {
            minimum: 100000000000000,
            message: 'pages.offline.register.fields.CGUerror',
          },
        }
      return null
    },
  },
]

export default inputs
