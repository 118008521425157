import { actionChannel } from 'redux-saga/effects'
import Episode from '../../pages/online/Episode'
import MentorPopup from '../../pages/online/MentorPopup'
import { IViewExtended } from '../reader/reducer'
import { IEpisodeObject, IHomeProgramObject, IProgramObject, ISubtitleObject } from './reducer'

export const getCurrentEpisodeToPlay = (
  program: IProgramObject | undefined,
  views: IViewExtended[],
  includeIntroduction = true,
): IEpisodeObject | undefined => {
  if (!program) return undefined
  let episode: IEpisodeObject | undefined = undefined
  if (isPlayableProgram(program)) {
    episode = getNextEpisode(program, views)
  } else {
    if (includeIntroduction) episode = program.episodes.find((e) => e.introduction)
  }
  return episode
}

export const getNextEpisodeToPlay = (
  program: IProgramObject | undefined,
  currentEpisodeId: number | undefined,
  views: IViewExtended[],
): IEpisodeObject | undefined => {
  if (!program) return undefined
  if (!currentEpisodeId) return getCurrentEpisodeToPlay(program, views)
  let next: boolean = false
  //let nextPlayableEpisode: IEpisodeObject | undefined = getCurrentEpisodeToPlay(program, views)
  //if (nextPlayableEpisode && nextPlayableEpisode.id !== currentEpisodeId) return nextPlayableEpisode
  let nextNaturalEpisode: IEpisodeObject | undefined = undefined
  program.episodes.forEach((e) => {
    if (next) {
      nextNaturalEpisode = e
      next = false
    } else {
      if (e.id === currentEpisodeId) {
        next = true
      }
    }
  })
  return nextNaturalEpisode
}

export const getNextEpisode = (program: IProgramObject, views: IViewExtended[]): IEpisodeObject => {
  let notFinished: IEpisodeObject[] = []
  program.episodes.forEach((e) => {
    let view = views.find((v) => e.id === v.episodeId)
    if ((!view || !view.complete) && !e.trailer) {
      notFinished.push(e)
    }
  })
  if (notFinished.length > 0) {
    return notFinished[0]
  }

  let epDef = program.episodes.find((e) => !e.trailer && !e.introduction)
  return epDef || program.episodes[1]
}

export const getNotFinishedEpisode = (
  program: IProgramObject,
  views: IViewExtended[],
  bonus = true,
): IEpisodeObject[] => {
  let notFinished: IEpisodeObject[] = []
  program.episodes.forEach((e) => {
    if (e.chapter.toLowerCase() === 'bonus' && !bonus) {
    } else {
      let view = views.find((v) => e.id === v.episodeId)
      if ((!view || !view.complete) && !e.introduction && !e.trailer && e.chapter !== 'conclusion') {
        notFinished.push(e)
      }
    }
  })
  return notFinished
}

export const isPlayableProgram = (program: IProgramObject | IHomeProgramObject | undefined): boolean => {
  if (!program) return false
  if (!program.purchase) return false
  if (program.purchase.status === 'GOT') return true
  return false
}

export const isProgramCompleted = (program: IProgramObject | undefined, views: IViewExtended[]): boolean => {
  if (!program) return false
  if (getNotFinishedEpisode(program, views, false).length === 0) return true
  return false
}
export const getNBRessources = (program: IProgramObject | undefined): string => {
  if (!program) return '0'
  let count = 0
  program.episodes.forEach((e) => (count += e.ressources ? e.ressources.length : 0))
  return count.toString()
}
export const getNBFasterclass = (program: IProgramObject | undefined): string => {
  return getNBFasterclassInt(program).toString()
}

export const getNBFasterclassInt = (program: IProgramObject | undefined): number => {
  if (!program) return 0
  let count = 0
  program.episodes.forEach((e) => (count += !e.introduction && !e.trailer && e.chapter !== 'conclusion' ? 1 : 0))
  return count
}
export const getProgramPercent = (program: IProgramObject | undefined, views: IViewExtended[]): number => {
  if (!program) return 0
  let nbFC: number = getNBFasterclassInt(program)
  let nbNotFinished: number = getNotFinishedEpisode(program, views).length
  return Math.round((1 - nbNotFinished / (nbFC > 0 ? nbFC : 1)) * 100)
}

export const getProgramPercentHome = (program: IHomeProgramObject | undefined, views: IViewExtended[]): number => {
  if (!program) return 0
  let nbFC: number = program.episodes.length
  let notFinished: number[] = []
  program.episodes.forEach((eId) => {
    let view = views.find((v) => eId === v.episodeId)
    if (!view || !view.complete) {
      notFinished.push(eId)
    }
  })
  let nbNotFinished = notFinished.length > 0 ? notFinished.length - 1 : 0
  if (nbNotFinished > nbFC) {
    nbFC = notFinished.length
  }
  let percent = Math.round((1 - nbNotFinished / (nbFC > 0 ? nbFC : 1)) * 100)
  return percent > 100 ? 100 : percent
}

export const programTranslate = (
  program: IProgramObject | undefined,
  lang: 'fr' | 'en',
): IProgramObject | undefined => {
  if (!program) return program
  let newProg: IProgramObject = JSON.parse(JSON.stringify(program))

  if (lang === 'fr' || !program.enReady) {
    newProg.episodes = newProg.episodes.map((episode) => {
      let stts: ISubtitleObject[] = []
      episode.subtitles &&
        episode.subtitles.forEach((st) => {
          if (st.epLang === 'fr') stts.push(st)
        })
      episode.subtitles = stts
      return episode
    })
    return newProg
  }

  newProg.title = newProg.titleEn
  newProg.description = newProg.descriptionEn
  newProg.imageTitle = newProg.imageTitleEn
  newProg.titlePicture = newProg.titlePictureEn
  newProg.title = newProg.titleEn
  newProg.classbookUrl = newProg.classbookUrlEn
  newProg.mentor.bio = newProg.mentor.bioEn
  newProg.mentor.description = newProg.mentor.descriptionEn
  newProg.episodes = newProg.episodes.map((episode) => {
    episode.title = episode.titleEn
    episode.description = episode.descriptionEn
    episode.duration = episode.durationEn
    episode.chapter = episode.chapterEn
    episode.videoid = episode.videoidEn
    episode.postit = episode.postitEn
    episode.actions = episode.actions.map((action) => {
      action.title = action.titleEn
      action.description = action.descriptionEn
      return action
    })
    episode.mentor = episode.mentorEn
    episode.mentor.bio = episode.mentor.bioEn
    episode.mentor.description = episode.mentor.descriptionEn
    episode.ressources = episode.ressources.map((ressource) => {
      if (!ressource) return ressource
      ressource.title = ressource.titleEn
      ressource.file = ressource.fileEn
      return ressource
    })
    let stts: ISubtitleObject[] = []
    episode.subtitles &&
      episode.subtitles.forEach((st) => {
        if (st.epLang === lang) stts.push(st)
      })
    episode.subtitles = stts

    return episode
  })
  return newProg
}
