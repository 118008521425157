import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import React from 'react'
import queryString from 'query-string'
import API from '../../../tools/fetch'
import Loader from '../../../components/Loader'
import { IStore } from '../../../store'
import imageResizer from '../../../tools/imageResizer'
import './ComboDisplay.scss'
import i18n from '../../../tools/i18n'

interface IProps {
  comboId: number
}
interface IState {
  loading: boolean
  combo:
    | {
        price: number
        title: string
        programs: {
          id: number
          description: string
          title: string
          picture: string
          titlePicture: string
          price: number
          available: string
          cpfready: string
          horsserie: string
        }[]
      }
    | undefined
}

class ComboDisplay extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      loading: true,
      combo: undefined,
    }
  }
  async componentDidMount() {
    const response = await API.get('/public/payment/combo', { params: { comboId: this.props.comboId } })
    this.setState({
      loading: false,
      combo: response.data,
    })
  }
  public render(): JSX.Element {
    const { combo, loading } = this.state
    if (!combo) return <Loader loading={true} />
    return (
      <div className="comboDisplayCheckout">
        {combo.programs.map((program) => (
          <div
            className="comboDisplayCheckout__image"
            style={{ backgroundImage: `url(${imageResizer(program.picture, 'homecaroussel')})` }}
          >
            <div className="comboDisplayCheckout__blackFilter">
              <div className="comboDisplayCheckout__spacer" />
              <div className="comboDisplayCheckout__infos">
                {program.horsserie && (
                  <div className="comboDisplayCheckout__titletag">{i18n.t('pages.checkout.comboDisplay.hs')}</div>
                )}
                <div className="comboDisplayCheckout__title">
                  <span>{program.title.split('$')[0]?.toUpperCase() || program.title}</span>
                  {program.title.split('$')[1]?.toUpperCase() || ''}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="comboDisplayCheckout__priceContainer">
          <div className="comboDisplayCheckout__comboTitle">{combo.title}</div>
          <div className="comboDisplayCheckout__priceTitle">{i18n.t('pages.checkout.comboDisplay.tarif')}</div>
          <div className="comboDisplayCheckout__price">
            {combo.price > 0 ? `${combo.price} € TTC` : i18n.t('pages.checkout.comboDisplay.free')}
          </div>
        </div>
      </div>
    )
  }
}

export default connect((store: IStore) => ({
  logged: store.user.logged,
}))(ComboDisplay)
