import { Router, Switch, Route, Redirect } from 'react-router-dom'
import history from '../tools/history'
import { connect } from 'react-redux'
import { IStore, IUserObject } from '../store/types'
import React from 'react'
import { getToken, setTokenListener } from '../tools/token'
import queryString from 'query-string'
import ProgramDisplay from './components/ProgramDisplay'
import './Checkout.scss'
import { USER_LOGOUT, USER_LOGOUT_PROCESS, USER_RELOAD_DATA } from '../store/user/constants'
import RegisterCheckout from './components/RegisterCheckout'
import LoginCheckout from './components/LoginCheckout'

import Logo from '../assets/images/logo_fasterclass.png'
import LoggedIn from './components/LoggedIn'
import intercom from '../tools/intercom'
import { ArrowRight } from 'react-feather'
import theme from '../theme'
import API from '../tools/fetch'
import ComboDisplay from './components/ComboDisplay'
import i18n from '../tools/i18n'

interface IProps {
  logged: boolean
  user: IUserObject
  match: any
  history: any
  location: any
  dispatch: (params: { type: string; payload?: any }) => void
}
interface IState {
  token: string | undefined | null
  programId: number | undefined
  comboId: number | undefined
  register: boolean
  loadingPayment: boolean
  currentErrors: string[]
}

class RouterRef extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    let qs = queryString.parse(this.props.location.search)
    this.state = {
      loadingPayment: false,
      token: getToken(),
      programId: typeof qs.programId !== 'string' ? undefined : Number.parseInt(qs.programId),
      comboId: typeof qs.comboId !== 'string' ? undefined : Number.parseInt(qs.comboId),
      register: true,
      currentErrors: [],
    }
  }
  stripe: any = undefined
  componentDidMount() {
    var scriptStripe = document.createElement('script')
    scriptStripe.src = 'https://js.stripe.com/v3'
    scriptStripe.onload = () => {
      // @ts-ignore
      this.stripe = window.Stripe(
        'pk_live_51HOhlbDXltYswLtD4xunfNKpg5jyK6LXNAvBbe3RzCUkRik44QN6CCo0vJ6j6uyr39MNPJbwNK2QN527NVgNrCmS00vnbjFGBz',
      )
    }
    document.head.appendChild(scriptStripe)

    var scriptHotJar = document.createElement('script')
    // @ts-ignore
    scriptHotJar.innerText = `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2147964,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
    document.head.appendChild(scriptHotJar)

    var scriptPixel = document.createElement('script')
    // @ts-ignore
    scriptPixel.innerText = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '132758374604423');
    fbq('track', 'PageView');`
    scriptPixel.defer = true
    document.head.appendChild(scriptPixel)

    setTokenListener(this.updateToken)
    this.props.dispatch({ type: USER_RELOAD_DATA })
  }
  public render(): JSX.Element {
    const { user } = this.props
    return (
      <div>
        <img src={Logo} className="checkout__fcLogo" />
        <div className="checkout">
          <div className="checkout__leftCol">
            <div className="checkout__leftColContainer">
              <div className="checkout__paymentSumTitle">{i18n.t('pages.checkout.title')}</div>
              {this.state.programId ? <ProgramDisplay programId={this.state.programId} /> : <div />}
              {this.state.comboId ? <ComboDisplay comboId={this.state.comboId} /> : <div />}
            </div>
          </div>
          <div className="checkout__middleCol">
            <ArrowRight size={32} color={theme.colors.white} />
          </div>
          <div className="checkout__rightCol">
            <div className="checkout__rightColContainer">
              {this.props.user && this.props.logged ? (
                <LoggedIn
                  user={this.props.user}
                  onChangeAccount={this.onChangeAccount}
                  onPayment={this.onPayment}
                  onLoad={this.refreshIntercom}
                  loadingPayment={this.state.loadingPayment}
                  paymentErrors={this.state.currentErrors}
                />
              ) : (
                <div>
                  {this.state.register ? (
                    <RegisterCheckout toggleLogin={this.toggleLogin} />
                  ) : (
                    <LoginCheckout toggleLogin={this.toggleLogin} />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  private onPayment = () => {
    this.setState({ loadingPayment: true })
    intercom.logEvent('checkout-go-to-payment', { comboId: this.state.comboId, programId: this.state.programId })
    setTimeout(
      () =>
        API.post('/private/payment/process', { programId: this.state.programId, comboId: this.state.comboId })
          .then((resp: any) => {
            return this.stripe.redirectToCheckout({ sessionId: resp.data.sessionId })
          })
          .catch((error: any, r: any) => {
            this.setState({ loadingPayment: false, currentErrors: error.response.data.codes })
          }),
      500,
    )
  }

  private refreshIntercom = () => {
    this.props.dispatch({ type: USER_RELOAD_DATA })
    setTimeout(
      () => intercom.logEvent('checkout-init', { comboId: this.state.comboId, programId: this.state.programId }),
      300,
    )
  }
  private updateToken = (token: string) => {
    this.setState({ token })
  }
  private toggleLogin = () => {
    this.setState({ register: !this.state.register })
  }
  private onChangeAccount = () => {
    intercom.shutdown()
    this.setState({ register: false }, () => {
      this.props.dispatch({ type: USER_LOGOUT_PROCESS })
    })
  }
}

export default connect((store: IStore) => ({
  logged: store.user.logged,
  user: store.user.data,
}))(RouterRef)
