const action = (action: string, args: any) => {
  // @ts-ignore
  window.Intercom(action, {
    app_id: 'vgulgzly',
    ...args,
  })
}

const logEvent = (event: string, args: any) => {
  // @ts-ignore
  window.Intercom('trackEvent', event, args)
}
const init = (args: any) => {
  action('boot', args)
}

const showNewMessage = (message: string) => {
  // @ts-ignore
  window.Intercom('showNewMessage', message)
}

const shutdown = () => {
  action('shutdown', {})
}

const intercom = {
  init,
  action,
  showNewMessage,
  logEvent,
  shutdown,
}
export default intercom
