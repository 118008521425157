import moment from 'moment'
import { ACTIONS_FETCH_ERROR, ACTIONS_FETCH_REQUEST, ACTIONS_FETCH_SUCCESS } from './constants'
import { USER_LOGOUT } from '../user/constants'

export interface IActionState {
  actionIds: number[]
  request: boolean
  error: boolean
}

const initialState: IActionState = {
  actionIds: [],
  request: false,
  error: false,
}

export default (
  state: IActionState = initialState,
  { type, payload }: { type: string; payload: any },
): IActionState => {
  switch (type) {
    case ACTIONS_FETCH_REQUEST:
      return {
        ...state,
        request: true,
      }
    case ACTIONS_FETCH_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      }
    case ACTIONS_FETCH_SUCCESS:
      return {
        actionIds: payload,
        request: false,
        error: false,
      }
    case USER_LOGOUT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
