import React from 'react'
import Form from '../../../../components/Form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import Step4 from './step4'

interface IProps {
  dispatch: (arg: { type: string; payload?: any }) => void
  lang: 'en' | 'fr'
}

interface IState {
  step: 1 | 2 | 3 | 4
  firstname: string
  lastname: string
  companyName: string
  email: string
  code: string
}

class StepManager extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      step: 1,
      firstname: '',
      lastname: '',
      companyName: '',
      email: '',
      code: '',
    }
  }

  public render(): JSX.Element | null {
    switch (this.state.step) {
      case 4:
        return (
          <Step4
            email={this.state.email}
            firstname={this.state.firstname}
            lastname={this.state.lastname}
            companyName={this.state.companyName}
            code={this.state.code}
            lang={this.props.lang}
          />
        )
      case 3:
        return <Step3 lang={this.props.lang} onValidSubmit={this.onValidStep3} email={this.state.email} />
      case 2:
        return <Step2 lang={this.props.lang} onValidSubmit={this.onValidStep2} firstname={this.state.firstname} />
      case 1:
      default:
        return <Step1 lang={this.props.lang} onValidSubmit={this.onValidStep1} />
    }
  }

  private onValidStep1 = (firstname: string, lastname: string, companyName: string) => {
    this.setState({
      step: 2,
      firstname,
      lastname,
      companyName,
    })
  }

  private onValidStep2 = (email: string) => {
    this.setState({
      step: 3,
      email,
    })
  }

  private onValidStep3 = (code: string) => {
    this.setState({
      step: 4,
      code,
    })
  }
}

export default StepManager
