const locales: any = {
  fr: {
    errors: {
      USER_NOT_FOUND: 'Cet email est inconnu, avez vous utilisé une autre adresse ?',
      UNKNOWN_ERROR: `Une erreur inconnue s'est produite, merci de réessayer ultérieurement ou de contacter le support.`,
      WRONG_PASSWORD_OR_EMAIL: `Le mot de passe est incorrect`,
    },
    pages: {
      online: {
        header: {
          hello: 'Bonjour %{name}',
          preniumT1: 'Tu es',
          preniumT2: 'Premium',
          sponsorInfor: 'Ton accès à certaines formations est sponsorisé par %{company}. ',
          sponsorInfoBtn: 'En savoir plus',
          sponsorInfoUrl: 'https://fasterdesk.notion.site/Compte-sponsoris-c597810717084557b12a780b628173d7',
          menu: {
            you: 'vous',
            actions: 'Actions à mettre en place',
            settings: 'Paramètres',
            password: 'Changer de mot de passe',
            logout: 'Se déconnecter',
            contribute: 'contribuer',
            suggest: 'Faire une suggestion',
            suggestText: `Bonjour, j'aimerais faire une suggestion sur l'application Fasterclass.`,
            subject: 'Suggérer un sujet',
            subjectText: `Bonjour, j'aimerais suggérer un sujet pour une prochaine Fasterclass.`,
            help: `Demander de l'aide`,
            helpText: `Bonjour, j'aimerais de l'aide sur Fasterclass.`,
            bug: 'Signaler un bug',
            bugText: `Bonjour, j'aimerais remonter un bug que j'ai trouvé sur Fasterclass.`,
          },
        },
        home: {
          hs: 'Hors-Série',
          ended: 'Terminé',
          inprogress: 'EN COURS',
          free: 'Gratuit*',
          interestyou: `Ce programme vous intérèsse ?`,
          soonav: 'PROCHAINEMENT DISPONIBLE',
          owned: 'Possédé',
          intercominterested: `Bonjour, je suis intéressé par le programme %{progTitle}. Pourriez vous m'en dire d'avantage sur sa date de sortie ou son contenu ?`,
          p1: 'TARIF',
          p2: 'offre spéciale',
          price: 'TTC',
          opco: '100% pris en charge par CPF & OPCO',
          langavailable: 'Langues disponibles',
          mine: 'Mes Fasterclass',
        },
        actions: {
          none: `Vous n'avez pas encore mis en place d'actions ! Suivez les Fasterclass et cochez les défis que vous aimeriez relever. Vous les retrouverez ensuite ici.`,
          title: 'Actions à mettre en place',
        },
        password: {
          title: 'Changer de mot de passe',
          btn: 'Enregistrer',
          mainError: 'Impossible de se connecter au serveur.',
          success: 'Le mot de passe a été modifié.',
          form: {
            actualPwd: 'Mot de passe actuel',
            newPwd: 'Nouveau mot de passe',
            newPwdError: 'Le mot de passe est trop court (8 caractères minimum)',
            newPwdBis: 'Confirmer nouveau mot de passe',
            newPwdBisError: 'Les mots de passe ne sont pas identiques',
          },
        },
        episode: {
          popupVote: {
            title: `Comment t'as trouvé l'épisode ?`,
            subtitle: `La note reste entre nous, c'est pour nous améliorer.`,
            further: 'Aller plus loin...',
            intercom: `Bonjour, j'aimerais vous poser une question au sujet de l'épisode que je viens de voir. Programme : %{program}, Episode : %{episode}`,
            title2: 'Une question ?',
            subtitle2: 'Le chat en bas à droite permet de poser des questions, on y répond au plus vite.',
            filesTitle: `N'oublie pas les ressources !`,
            filesSub: `As-tu vu les ressources sur la gauche de l'épisode ? Elles sont ici pour t'aider à approfondir et t'accompagner dans tes démarches si besoin.`,
            next: 'continuer',
          },
          back: 'Retour',
          files: 'ressources à télécharger',
          memo: 'mémo à emporter',
          next: 'Episode suivant',
          trailer: 'Bande annonce',
          by: 'par',
          episodeN: 'EPISODE %{number}',
          intro: 'INTRODUCTION',
        },
        mentor: {
          experiences: 'Parcours',
          collabs: {
            zero: 'Aucun',
            one: 'Un collaborateur',
            other: '%{count} collaborateurs',
          },
          time: {
            zero: 'Aucun',
            one: '1 an',
            other: '%{count} ans',
          },
        },
        settings: {
          title: 'Profil',
          btn: 'Enregistrer',
          mainError: 'Impossible de se connecter au serveur.',
          success: 'Les modifications ont été enregistrées.',
          fields: {
            email: 'Email',
            firstname: 'Prénom',
            lastname: 'Nom',
            companyName: 'Entreprise',
          },
        },
        program: {
          hs: 'Hors-Série',
          start: 'Regarder le programme',
          buy: 'Acheter le programme',
          get: 'Obtenir le programme',
          getIntercom: `Bonjour, Je souhaite accéder à la Fasterclass %{type} : %{title}!`,
          viewBA: 'Regarder la bande annonce',
          by: 'parrainé par',
          languages: 'Disponible en',
          include: 'Inclus',
          xFastercl: {
            zero: 'Aucune Fasterclass exclusive',
            one: 'Une Fasterclass exclusive',
            other: '%{count} Fasterclass exclusives',
          },
          xRessources: {
            zero: 'Aucune ressource',
            one: 'Une Fiche de synthèse',
            other: '%{count} Fiches de synthèses & Ressources',
          },
          certificate: `Certificat final`,
          teachers: `vos enseignants`,
          classbook: `Classbook`,
          classbooksub: `Cette ressource synthétise tous les apprentissages clés du programme et t'accompagnera tout au long des épisodes.`,
          episodes: 'Episodes',
          intro: 'introduction',
          certifCard: {
            title: 'Certificat final du programme',
            complete:
              'Félicitations ! Votre certificat sera signé et vous sera envoyé par nos soins très prochainement.',
          },
        },
      },
      components: {
        sib: {
          download: 'Télécharger',
        },
        mobileDisplay: {
          h1: `Vous allez être redirigé vers la page de l'application Fasterclass pour mobile...`,
          h2: `Si vous n'êtes pas redirigé vous pouvez cliquer sur ces liens : `,
          h1bis: `Cette application web n'est pas disponible sur mobile, merci de bien vouloir utiliser l'application Fasterclass disponible sur vos stores favoris !`,
          appstore: `App store (Iphone/Ipad)`,
          playstore: `Play store (Androïd)`,
          appstoreUri: `https://apps.apple.com/fr/app/fasterclass/id1539417939`,
          playstoreUri: `https://play.google.com/store/apps/details?id=com.ouispoon.fasterclass`,
        },
        actionCard: {
          willtry: `J'accepte le défi`,
          tryed: 'Défi accepté',
        },
      },
      checkout: {
        title: 'Votre commande',
        comboDisplay: {
          hs: 'Hors-Série',
          tarif: 'TARIF',
          free: 'Gratuit*',
        },
        loggedIn: {
          title: 'Pour continuer, veuillez vérifier que vous êtes connecté au bon compte.',
          text1: 'Vous êtes connecté en tant que :',
          btn1: 'Continuer vers le paiement',
          btn2: 'Changer de compte',
          errors: {
            PROGRAM_NOT_FOUND: `Le programme est introuvable, veuillez nous contacter pour plus d'informations.`,
            PROGRAM_OWNED: `Vous possédez déjà ce programme ! Connectez vous à l'application pour le visionner.`,
            ONE_OF_PROGRAM_OWNED: `Vous possédez déjà un des programmes que vous essayez d'acheter.`,
            default: `Une erreur inconnue est survenue. Si cela se reproduit n'hésitez pas à nous contacter avec le Chat en bas à droite.`,
          },
        },
      },
      offline: {
        home: {
          h1: 'Bienvenue',
          link: 'En savoir plus.',
          linkTarget: 'https://www.fasterclass.fr',
          loginBtn: 'Se connecter',
          registerBtn: `S'inscrire`,
          puce1: 'Contenus exclusifs 100% vidéo & audio',
          puce2: 'Espace Q&A personnel',
          puce3: 'Fiches de synthèses & Ressources',
        },
        login: {
          title: 'Connexion',
          inputEmail: 'E-mail',
          inputPwd: 'Mot de passe',
          loginBtn: 'Se connecter',
          description: 'Pour continuer, veuillez vous connecter ou vous inscrire.',
          resetPwd: 'Mot de passe oublié',
          lineCreate: 'Vous voulez créer un compte ?',
          createAction: `S'inscrire`,
        },
        register: {
          nextBtn: 'Continuer',
          mainError: 'Impossible de se connecter au serveur, veuillez réessayer.',
          step1: `Bonjour, commençons par se présenter !`,
          fields: {
            firstname: 'Prénom',
            lastname: 'Nom',
            companyName: 'Entreprise',
            email: 'Email',
            verifCode: 'Code de vérification',
            password: 'Mot de passe',
            passwordError: 'Le mot de passe est trop court (8 caractères minimum)',
            passwordLegend: 'Au moins 8 caractères',
            CGUerror: `Merci d'accepter les conditions avant de vous inscrire.`,
          },
          step2btn: `Recevoir mon code`,
          welcome: 'Bienvenue',
          welcome2: `, nous allons vérifier votre email en vous envoyant un code :`,
          emailAlreadyUse: `L'email est déjà utilisé sur un autre compte.`,
          step3title: {
            p1: 'Nous avons envoyé un ',
            p2: 'code',
            p3: ' de vérification à ',
            useless: 'VAR:EMAIL',
            p4: ', merci de le renseigner pour continuer.',
          },
          step3btn: 'Confirmer mon email',
          wrongCode: 'Le code est incorrect.',
          step4Title: `Et voilà c'est presque fini ! Renseignez un mot de passe pour pouvoir vous reconnecter la prochaine fois.`,
          step4Btn: `Terminer l'inscription`,
          CGUtext1: 'En vous inscrivant vous acceptez ',
          CGUtext2: 'notre règlement intérieur.',
          CGUlink: 'https://fasterdesk.notion.site/R-glement-int-rieur-09585c7b16dd46d1afba4d59f5719569',
          title: 'Inscription',
          alreadyAcc: 'Vous avez déjà un compte ? ',
          alreadyAccBtn: 'Se connecter',
        },
        resetPwd: {
          title: `Si votre email existe, <span>un email avec un code de vérification vous a été envoyé.</span> Veuillez renseigner ce même code ci-dessous.`,
          buttonText: 'Changer mon mot de passe',
          mainError: 'Une erreur est survenue, veuillez vérifier votre email ou votre code de vérification.',
          successText: 'Le mot de passe a été modifié.',
          rdyTo: 'Prêt à vous connecter ? ',
          back: 'Retour',
          btnTxt1: 'Réinitialiser mon MDP',
          fields: {
            email: 'Email',
            code: 'Code de vérification reçu par email',
            pwd: 'Nouveau mot de passe',
            pwdError: 'Le mot de passe est trop court (8 caractères minimum)',
            pwdBis: 'Nouveau MdP Bis',
            pwdBisError: 'Les mots de passe ne sont pas identiques',
          },
        },
      },
    },
  },
  en: {
    errors: {
      USER_NOT_FOUND: 'This email is unknown, did you use another address?',
      UNKNOWN_ERROR: `An unknown error has occurred, please try again later or contact support.`,
      WRONG_PASSWORD_OR_EMAIL: `Wrong password or email`,
    },
    pages: {
      online: {
        header: {
          hello: 'Hello %{name}',
          preniumT1: 'You are',
          preniumT2: 'Premium',
          sponsorInfor: 'Your access to certain courses is sponsored by %{company}.',
          sponsorInfoBtn: 'Learn more',
          sponsorInfoUrl: 'https://fasterdesk.notion.site/Compte-sponsoris-c597810717084557b12a780b628173d7',
          menu: {
            you: 'you',
            actions: 'Actions to take',
            settings: 'Settings',
            password: 'Change pasword',
            logout: 'Log out',
            contribute: 'contribute',
            suggest: 'Make a suggestion',
            suggestText: `Hello, I would like to make a suggestion on the Fasterclass app.`,
            subject: 'Suggest a subject',
            subjectText: `Hello, I would like to suggest a subject for an upcoming Fasterclass.`,
            help: `Ask for help`,
            helpText: `Hello, I would like some help on Fasterclass.`,
            bug: 'Report a bug',
            bugText: `Hello, I would like to report a bug I found on Fasterclass.`,
          },
        },
        home: {
          hs: 'Special Issue',
          ended: 'Done',
          inprogress: 'IN PROGRESS',
          free: 'Free*',
          interestyou: `Are you interested in this program?`,
          soonav: 'AVAILABLE SOON',
          owned: 'Owned',
          intercominterested: `Hello, I am interested in the program %{progTitle}. Could you tell me more about its release date or content?`,
          langavailable: 'Available languages',
          p1: 'PRICE',
          p2: 'special offer',
          price: 'VAT',
          opco: '100% supported by CPF & OPCO',
          mine: 'My Fasterclass',
        },
        actions: {
          none: `You haven't taken any actions yet! Watch the Fasterclasses and accept the challenges associated with them. You'll find the challenges accepted here.`,
          title: 'Actions to take',
        },
        password: {
          title: 'Change Password',
          btn: 'Save',
          mainError: 'Could not connect to server.',
          success: 'The password has been changed.',
          form: {
            actualPwd: 'Current password',
            newPwd: 'New password',
            newPwdError: 'Password is too short (8 characters minimum)',
            newPwdBis: 'Confirm new password',
            newPwdBisError: 'Passwords are not identical',
          },
        },
        episode: {
          popupVote: {
            title: `How did you find the episode?`,
            subtitle: `The grade stays between us, it's to improve us.`,
            further: 'Go further...',
            intercom: `Hello, I would like to ask you a question about the episode I just watched. Program: %{program}, Episode: %{episode}`,
            title2: 'A question?',
            subtitle2:
              'The chat at the bottom right allows you to ask questions, we answer them as quickly as possible.',
            filesTitle: `Don't forget the resources!`,
            filesSub: `Have you seen the resources on the left of the episode? They are here to help you go deeper and to accompany you in your steps if necessary.`,
            next: 'continue',
          },
          back: 'Back to program',
          files: 'resources to download',
          memo: 'to-go memo',
          next: 'Next episode',
          trailer: 'Trailer',
          by: 'by',
          episodeN: 'EPISODE %{number}',
          intro: 'INTRODUCTION',
        },
        mentor: {
          experiences: 'Experiences',
          collabs: {
            zero: 'None',
            one: 'A collaborator',
            other: '%{count} collaborators',
          },
          time: {
            zero: 'None',
            one: '1 year',
            other: '%{count} years',
          },
        },
        settings: {
          title: 'Profile',
          btn: 'Save',
          mainError: 'Could not connect to server.',
          success: 'Changes have been saved.',
          fields: { email: 'Email', firstname: 'First name', lastname: 'Name', companyName: 'Company' },
        },
        program: {
          hs: 'Special Issue',
          start: 'Watch the program',
          buy: 'Buy the program',
          get: 'Get the program',
          getIntercom: `Hello, I want to access Fasterclass %{type}: %{title}!`,

          viewBA: 'Watch trailer',
          by: 'sponsored by',
          languages: 'Available languages',
          include: 'Included',
          xFastercl: {
            zero: 'No exclusive Fasterclass',
            one: 'An exclusive Fasterclass',
            other: '%{count} exclusives Fasterclass',
          },
          xRessources: { zero: 'No resources', one: 'A Summary Sheet', other: '%{count} Fact Sheets & Resources' },
          certificate: `final certificate`,
          teachers: `your teachers`,
          classbook: `Classbook`,
          classbooksub: `This resource synthesizes all the key learnings from the program and will guide you through the episodes.`,
          episodes: 'Episodes',
          intro: 'intro',
          certifCard: {
            title: 'Program Final Certificate',
            complete: 'Congratulation ! Your certificate will be signed and will be sent to you by us very soon.',
          },
        },
      },
      components: {
        sib: {
          download: 'Download',
        },
        mobileDisplay: {
          h1: `You will be redirected to the Fasterclass mobile app page...`,
          h2: `If you are not redirected you can click on these links: `,
          h1bis: `This web application is not available on mobile, please use the Fasterclass application available on your favorite stores!`,
          appstore: `App store (Iphone/Ipad)`,
          playstore: `Play store (Android)`,
          appstoreUri: `https://apps.apple.com/fr/app/fasterclass/id1539417939`,
          playstoreUri: `https://play.google.com/store/apps/details?id=com.ouispoon.fasterclass`,
        },
        actionCard: {
          willtry: `I accept the challenge`,
          tryed: 'Challenge accepted',
        },
      },
      checkout: {
        title: 'Your order',
        comboDisplay: { hs: 'Special Issue', tarif: 'TARIF', free: 'Free*' },
        loggedIn: {
          title: 'To continue, please verify that you are logged into the correct account.',
          text1: 'You are logged in as:',
          btn1: 'Proceed to payment',
          btn2: 'Change account',
          errors: {
            PROGRAM_NOT_FOUND: `The program was not found, please contact us for more information.`,
            PROGRAM_OWNED: `You already own this program! Log in to the application to view it.`,
            ONE_OF_PROGRAM_OWNED: `You already own one of the programs you are trying to buy.`,
            default: `An unknown error has occurred. If this happens again do not hesitate to contact us with the Chat at the bottom right.`,
          },
        },
      },
      offline: {
        home: {
          h1: 'Welcome',
          link: 'Find out more.',
          linkTarget: 'https://www.fasterclass.fr',
          loginBtn: 'Login',
          registerBtn: `Register`,
          puce1: '100% exclusive video & audio content',
          puce2: 'Personal Q&A space',
          puce3: 'Summary sheets & Resources',
        },
        login: {
          title: 'Login',
          inputEmail: 'Email',
          inputPwd: 'Password',
          loginBtn: 'Login',
          description: 'To continue, please login or register.',
          resetPwd: 'Forgot password',
          lineCreate: 'Want to create an account?',
          createAction: `Register`,
        },
        register: {
          nextBtn: 'Continue',
          mainError: 'Unable to connect to server, please try again.',
          step1: `Hello, let's introduce ourselves!`,
          fields: {
            firstname: 'First name',
            lastname: 'Name',
            companyName: 'Company',
            email: 'Email',
            verifCode: 'Verification Code',
            password: 'Password',
            passwordError: 'The password is too short (8 characters minimum)',
            passwordLegend: 'At least 8 characters',
            CGUerror: `Please accept the terms before registering.`,
          },
          step2btn: `Receive my code`,
          welcome: 'Welcome',
          welcome2: `, we will verify your email by sending you a code:`,
          emailAlreadyUse: `Email is already in use on another account.`,
          step3title: {
            p1: 'We sent a verification ',
            p2: 'code',
            p3: ' at ',
            useless: 'VAR:EMAIL',
            p4: ', please enter it to continue.',
          },
          step3btn: 'Confirm my email',
          wrongCode: 'The code is incorrect.',
          step4Title: `And now it's almost finished! Fill in a password to be able to log in again next time.`,
          step4Btn: `Complete registration`,
          CGUtext1: 'By registering you agree ',
          CGUtext2: 'our rules of procedure.',
          CGUlink: 'https://fasterdesk.notion.site/R-glement-int-rieur-09585c7b16dd46d1afba4d59f5719569',
          title: 'Registration',
          alreadyAcc: 'Already have an account? ',
          alreadyAccBtn: 'Connect',
        },
        resetPwd: {
          title: `If your email exists, <span>an email with a verification code has been sent to you.</span> Please enter the same code below.`,
          buttonText: 'Change my password',
          mainError: 'An error occurred, please check your email or verification code.',
          successText: 'Password has been changed.',
          rdyTo: 'Ready to connect? ',
          back: 'Back',
          btnTxt1: 'Reset my password',
          fields: {
            email: 'Email',
            code: 'Verification code received by email',
            pwd: 'New password',
            pwdError: 'Password is too short (8 characters minimum)',
            pwdBis: 'New Password Bis',
            pwdBisError: 'Passwords are not identical',
          },
        },
      },
    },
  },
}
export default locales
