import React from 'react'
import Star from './Star'
import './Rater.scss'

interface IProps {
  note?: number
  onVote: (note: number) => void
  locked: boolean
}

export default class Rater extends React.PureComponent<IProps> {
  componentDidMount() {}

  public render(): JSX.Element {
    return (
      <div className={'rater'}>
        <div className={'rater__starContainer'}>
          {[0, 1, 2, 3, 4].map((i: number) => (
            <Star
              index={i}
              active={this.props.note ? this.props.note >= i : false}
              onPress={this.onPressStar}
              key={`rater-star-${i}`}
            />
          ))}
        </div>
        <div className={'rater__legend'}>
          {/*<div className={'rater__legendText'}>Aucun intérêt</div>
          <div className={'rater__legendText'}>Intéressant à creuser</div>
          <div className={'rater__legendText'}>Je vais implémenter</div>*/}
        </div>
      </div>
    )
  }

  private onPressStar = (index: number): void => {
    if (!this.props.locked) this.props.onVote(index)
  }
}
