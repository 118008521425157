import moment from 'moment'
import { IViewObject } from '../programs/reducer'
import { USER_LOGOUT } from '../user/constants'
import { CHANGE_MODE_READER, VIEWS_UPDATE_SUCCESS, VIEW_PROGRESS } from './constants'

export interface IReaderState {
  views: IViewExtended[]
  mode: 'audio' | 'video'
}

export interface IViewExtended extends IViewObject {
  episodeId: number
}

const initialState: IReaderState = {
  views: [],
  mode: 'video',
}

export default (
  state: IReaderState = initialState,
  { type, payload }: { type: string; payload: any },
): IReaderState => {
  switch (type) {
    case CHANGE_MODE_READER:
      return {
        ...state,
        mode: payload,
      }
    case VIEW_PROGRESS:
      let stateChanged = false
      let newState = {
        ...state,
        views: state.views.map((view) => {
          if (view.episodeId !== payload.episodeId) return view
          stateChanged = true
          return {
            ...view,
            episodeId: payload.episodeId,
            currentDuration: payload.currentDuration,
            complete: payload.complete !== undefined ? payload.complete : view.complete,
            date: moment().toString(),
            note: payload.note !== undefined ? payload.note : view.note,
            type: 'video',
          }
        }),
      }
      if (!stateChanged) {
        newState.views.push({
          episodeId: payload.episodeId,
          currentDuration: false,
          complete: payload.complete,
          date: moment().toString(),
          note: -1,
          type: 'video',
        })
      }
      return newState
    case VIEWS_UPDATE_SUCCESS:
      return {
        ...state,
        views: payload,
      }

    case USER_LOGOUT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
