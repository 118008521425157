import { IFormObject } from '../../../components/Form'
import i18n from '../../../tools/i18n'

const inputs: IFormObject[] = [
  {
    name: 'email',
    title: 'pages.offline.resetPwd.fields.email',
    type: 'text',
    half: false,
    validation: { presence: { allowEmpty: false, message: 'NONE' }, email: { message: 'NONE' } },
  },
]

export default inputs
