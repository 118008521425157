import React from 'react'
import Form from '../../../../components/Form'
import inputs from './step4form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import theme from '../../../../theme'
import API from '../../../../tools/fetch'
import { LOGIN } from '../../../../store/user/constants'
import i18n from '../../../../tools/i18n'

interface IProps {
  email: string
  firstname: string
  lastname: string
  companyName: string
  code: string
  dispatch: (arg: { type: string; payload?: any }) => void

  lang: 'en' | 'fr'
}

interface IState {
  requesting: boolean
  errored: boolean
  injectedErrors: { [key: string]: string[] }
}

class Register extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      requesting: false,
      errored: false,
      injectedErrors: {},
    }
  }

  public render(): JSX.Element | null {
    return (
      <div>
        <div style={{ marginTop: '-5px', marginBottom: '10px', maxWidth: '300px' }}>
          {i18n.t('pages.offline.register.step4Title')}
        </div>
        <Form
          forms={inputs}
          defaultValues={[]}
          buttonText={i18n.t('pages.offline.register.step4Btn')}
          onValidSubmit={this.onValidSubmit}
          loading={this.state.requesting}
          mainError={this.state.errored}
          mainErrorText={i18n.t('pages.offline.register.mainError')}
          injectedErrors={this.state.injectedErrors}
          lang={this.props.lang}
        />
      </div>
    )
  }

  private onValidSubmit = async (values: { [key: string]: string }) => {
    this.setState({
      requesting: true,
      errored: false,
      injectedErrors: {},
    })
    try {
      await API.post('/auth/register', {
        ...values,
        email: this.props.email,
        firstname: this.props.firstname,
        lastname: this.props.lastname,
        companyName: this.props.companyName,
        code: this.props.code,
      })
      this.props.dispatch({
        type: LOGIN,
        payload: {
          email: this.props.email,
          password: values.password,
          registered: true,
        },
      })
    } catch (error) {
      if (error.response?.data?.codes && error.response.data.code[0]) {
        if (error.response.data.code[0] === 'INCORECT_CODE') {
          this.setState({
            requesting: false,
            errored: true,
            injectedErrors: {},
          })
        } else this.setState({ requesting: false, errored: true, injectedErrors: {} })
      } else this.setState({ requesting: false, errored: true, injectedErrors: {} })
    }
  }
}

export default connect()(Register)
