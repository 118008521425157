import React, { CSSProperties } from 'react'
import { Download, Lock } from 'react-feather'
import i18n from '../../tools/i18n'
import Button from '../Button'
import './SmallImageButtonCard.scss'

interface IProps {
  image: string
  text: string
  subtext: string
  actionType?: 'download'
  actionLink?: string
  onLinkClick?: (link: string) => void
  locked?: boolean
  highLighted?: boolean
}

export default class SmallImageButtonCard extends React.Component<IProps> {
  public render(): JSX.Element {
    return (
      <div className={`smallImageButtonCard ${this.props.highLighted ? ' smallImageButtonCardHL' : ''}`}>
        <div className="smallImageButtonCard__topContainer">
          <div className="smallImageButtonCard__imgContainer">
            <img src={this.props.image} key={this.props.image} />
          </div>
          <div className="smallImageButtonCard__textContainer">
            <div className="smallImageButtonCard__text">{this.props.text}</div>
            <div className="smallImageButtonCard__subtext">{this.props.subtext}</div>
          </div>
        </div>
        {this.props.actionType && (
          <div className="smallImageButtonCard__actionContainer">
            {this.props.locked ? (
              <Button onClick={() => {}} locked={true} theme={'locked'} style={{ width: '100%' }}>
                <Lock /> {i18n.t('pages.components.sib.download')}
              </Button>
            ) : (
              <a href={this.props.actionLink} target="_blank" onClick={this.onLinkClick}>
                <Button onClick={() => {}} style={{ width: '100%' }}>
                  <Download /> {i18n.t('pages.components.sib.download')}
                </Button>
              </a>
            )}
          </div>
        )}
      </div>
    )
  }
  private onLinkClick = () => {
    this.props.onLinkClick && this.props.onLinkClick(this.props.actionLink || '')
    return true
  }
}
