import { IFormObject } from '../../../components/Form'
import i18n from '../../../tools/i18n'

const inputs: IFormObject[] = [
  {
    name: 'email',
    title: 'pages.offline.resetPwd.fields.email',
    type: 'text',
    locked: true,
    half: false,
    validation: {},
  },
  {
    name: 'code',
    title: 'pages.offline.resetPwd.fields.code',
    type: 'text',
    half: false,
    validation: { presence: { allowEmpty: false, message: 'NONE' } },
  },
  {
    name: 'password',
    title: 'pages.offline.resetPwd.fields.pwd',
    type: 'password',
    half: false,
    validation: {
      presence: { allowEmpty: false, message: 'NONE' },
      /*regex: {
        pattern: '(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])',
        message: 'Must contain a capital, lowercase, and number',
      },*/
      length: {
        minimum: 8,
        message: 'pages.offline.resetPwd.fields.pwdError',
      },
    },
  },
  {
    name: 'newPasswordBis',
    title: 'pages.offline.resetPwd.fields.pwdBis',
    type: 'password',
    half: false,
    validation: function (value: any, attributes: any, attributeName: any, options: any, constraints: any) {
      if (attributes.password !== '' && attributes.password !== value)
        return {
          length: {
            minimum: 100000000000000,
            message: 'pages.offline.resetPwd.fields.pwdBisError',
          },
        }
      return null
    },
  },
]

export default inputs
