import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import Header from '../../components/Header/Header'
import { IStore } from '../../store'
import { VIEWS_UPDATE } from '../../store/reader/constants'
import { APP_RUNNING } from '../../store/trackEvent/constants'
import { IUserObject } from '../../store/types'
import { USER_LANG_CHANGE, USER_LOGOUT_PROCESS, USER_RELOAD_DATA } from '../../store/user/constants'

import HomeV3 from './HomeV3'
import Settings from './Settings'
import Actions from './Actions'
import './OnlineRouter.scss'
import ChangePassword from './ChangePassword'
import intercom from '../../tools/intercom'
import Loader from '../../components/Loader'
import ProgramRouter from './ProgramRouter'

interface IProps {
  user: IUserObject
  dispatch: (props: { type: string; payload?: any }) => void
  loadingPrograms: boolean
  loadingUser: boolean
  lang: 'en' | 'fr'
}

class OnlineRouter extends React.Component<IProps> {
  private interval: any = undefined

  private refreshReaderData = () => {
    this.props.dispatch({ type: VIEWS_UPDATE })
    this.props.dispatch({ type: APP_RUNNING })
  }
  public componentDidMount() {
    this.props.dispatch({ type: USER_RELOAD_DATA })
    this.interval = setInterval(this.refreshReaderData, 30000)
  }
  public componentWillUnmount() {
    this.interval = clearInterval(this.interval)
    intercom.shutdown()
  }
  render() {
    return (
      <div className="onlineRouter">
        <Loader loading={this.props.loadingUser} />
        <Header
          firstname={this.props.user.firstname || ''}
          processLogout={this.processLogout}
          premiumCompany={this.props.user.premiumCompany}
          lang={this.props.lang}
          toggleLang={this.toggleLang}
        />

        {!this.props.loadingPrograms && (
          <Switch>
            <Route path="/online/program/:programId" component={ProgramRouter} />
            <Route exact path="/online/" component={HomeV3} />
            <Route exact path="/online/settings" component={Settings} />
            <Route exact path="/online/actions" component={Actions} />
            <Route exact path="/online/password" component={ChangePassword} />
            <Redirect to="/online/" />
          </Switch>
        )}
      </div>
    )
  }

  private toggleLang = (lang: 'en' | 'fr') => {
    this.props.dispatch({ type: USER_LANG_CHANGE, payload: { lang } })
  }
  private processLogout = () => {
    this.props.dispatch({ type: USER_LOGOUT_PROCESS })
  }
}

export default connect((store: IStore) => ({
  user: store.user.data,
  loadingUser: store.user.isRequesting,
  loadingPrograms: store.programs.isRequesting,
  lang: store.user.lang,
}))(OnlineRouter)
