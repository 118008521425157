import React from 'react'
import { connect } from 'react-redux'
import { IStore } from '../../../store/types'
import { IViewExtended } from '../../../store/reader/reducer'
import { IEpisodeObject, IMentorObject, IProgramObject } from '../../../store/programs/reducer'
import './Episode.scss'
import GreyUpSpace from '../../../components/Texts/GreyUpScale'
import SmallImageCard from '../../../components/SmallImageCard'
import postit from '../../../assets/images/image_memo_jaune.png'
import { EPISODE_ENDED, EPISODE_OPENED, EPISODE_READING, OPENNING_RESSOURCE } from '../../../store/trackEvent/constants'
import moment from 'moment'
import { VIEW_PROGRESS } from '../../../store/reader/constants'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import Rater from './Rater'
import noteIcon from '../../../assets/images/icon_note.png'
import chatIcon from '../../../assets/images/icon_chat.png'
import docsIcon from '../../../assets/images/no_question.png'
import Button from '../../../components/Button'
import { ArrowLeft, ArrowRight, X } from 'react-feather'
import theme from '../../../theme'
import { getNextEpisodeToPlay, isPlayableProgram, programTranslate } from '../../../store/programs/tools'
import imageResizer from '../../../tools/imageResizer'
import Separator from '../../../components/Separator'
import intercom from '../../../tools/intercom'
import PictoButton from '../../../components/PictoButton'
import SmallMentorCard from '../../../components/SmallMentorCard'
import { MENTOR_POPUP_OPEN } from '../../../store/mentorPopup/constants'
import ActionCard from '../../../components/ActionCard'
import i18n from '../../../tools/i18n'
import { IViewObject } from '../../../store/programs copy/reducer'

interface IProps {
  match: any
  view?: IViewExtended
  program: IProgramObject | undefined
  episode: IEpisodeObject | undefined
  nextEpisode?: IEpisodeObject
  history: any
  lang: 'fr' | 'en'
  dispatch: (object: { type: string; payload?: any }) => void
}

interface IState {
  lastSecondCheck: number
  endModalVisible: boolean
  votingModal: boolean
  votingModalLocked: boolean
  highLightDocs: boolean
}

class Episode extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = {
      lastSecondCheck: 0,
      endModalVisible: false,
      votingModal: true,
      votingModalLocked: false,
      highLightDocs: false,
    }
  }
  private player: any = undefined
  componentDidMount() {
    if (this.props.episode) {
      this.props.dispatch({ type: EPISODE_OPENED, payload: { episode: this.props.episode } })

      this.loadPlayer()
    }
  }
  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.episode === undefined && this.props.episode) {
      this.props.dispatch({ type: EPISODE_OPENED, payload: { episode: this.props.episode } })

      this.loadPlayer()
    }
    if (prevProps.lang !== this.props.lang) {
      this.loadPlayer()
    }
    if (this.props.match.params.episodeId !== prevProps.match.params.episodeId) {
      this.setState(
        {
          endModalVisible: false,
          votingModal: true,
          votingModalLocked: false,
        },
        () => {
          this.loadPlayer()
        },
      )
    }
  }

  componentWillUnmount() {
    //@ts-ignore
    this.player.stopCasting()
  }
  private loadPlayer = (): void => {
    if (this.props.view?.complete) {
      this.setState({ votingModal: false })
    }
    // @ts-ignore
    window.jwplayer('episodeplayer').setup({
      playlist: [
        {
          // @ts-ignore
          file: 'https://cdn.jwplayer.com/manifests/' + this.props.episode.videoid + '.m3u8',

          // @ts-ignore
          tracks: this.props.episode.subtitles.map((st) => ({
            kind: 'captions',
            file: st.url,
            label: st.lang,
          })),
        },
      ],
    })
    // @ts-ignore
    this.player = window.jwplayer('episodeplayer')

    this.player.on('ready', () => {
      if (this.props.view && this.props.view.currentDuration > 0) {
        this.player.seek(this.props.view.currentDuration)
        // TODO uniquement quand lecture
        this.props.dispatch({ type: EPISODE_READING, payload: { episode: this.props.episode } })
      }
    })
    this.player.on('complete', this.onEnd)
    this.player.on('time', this.onProgress)
  }

  private onEnd = (): void => {
    //@ts-ignore
    this.player.stopCasting()
    this.props.dispatch({
      type: VIEW_PROGRESS,
      payload: {
        episodeId: this.props.episode?.id || 0,
        complete: true,
        currentDuration: 0,
        type: 'video',
        date: moment().toString(),
        note: this.props.view?.note,
      },
    })
    setTimeout(() => {
      this.props.dispatch({
        type: EPISODE_ENDED,
        payload: { episode: this.props.episode, program: this.props.program },
      })
    }, 200)
    // @ts-ignore
    if (this.props.episode.introduction || this.props.episode.trailer) this.props.history.goBack()
    else this.setState({ endModalVisible: true })
  }

  private onProgress = (event: { duration: number; position: number; currentTime: number }): void => {
    //case chromecast on end
    if (this.state.lastSecondCheck === Math.round(event.duration) && event.currentTime === 0) {
      this.onEnd()
    } else {
      let currentDuration = Math.round(event.currentTime)
      if (currentDuration !== this.state.lastSecondCheck) {
        this.setState({ lastSecondCheck: currentDuration }, () => {
          this.props.dispatch({
            type: VIEW_PROGRESS,
            payload: {
              episodeId: this.props.episode?.id || 0,
              currentDuration: currentDuration,
              type: 'video',
              date: moment().toString(),
              note: this.props.view?.note,
            },
          })
        })
      }
    }
  }

  private onVote = (note: number) => {
    this.setState({ votingModalLocked: true }, () => {
      this.props.dispatch({
        type: VIEW_PROGRESS,
        payload: {
          episodeId: this.props.episode?.id || 0,
          complete: true,
          currentDuration: 0,
          type: 'video',
          date: moment().toString(),
          note: note,
        },
      })
      setTimeout(() => {
        this.setState({ votingModal: false })
      }, 500)
    })
  }

  public render(): JSX.Element {
    const { program, episode, nextEpisode } = this.props
    if (!program || !episode) return <div></div>
    return (
      <div className="episodes">
        <Popup
          position="center center"
          modal={true}
          closeOnDocumentClick={!this.state.votingModal}
          open={this.state.endModalVisible}
        >
          <div className="episodes__popup">
            {this.state.votingModal ? (
              <div className="episodes__popupContainer episodes__popupContainerRater">
                <img src={noteIcon} style={{ width: '80px' }} />
                <span className="episodes__popupTitle">{i18n.t('pages.online.episode.popupVote.title')}</span>
                <span className="episodes__popupSubTitle">{i18n.t('pages.online.episode.popupVote.subtitle')}</span>
                <Rater onVote={this.onVote} note={this.props.view?.note} locked={this.state.votingModalLocked} />
              </div>
            ) : (
              <div className="episodes__popupContainer episodes__popupContainerRater">
                <PictoButton
                  onClick={() => this.setState({ endModalVisible: false })}
                  icon={<X size={18} color={theme.colors.white} />}
                  style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                  }}
                />
                <span className="episodes__popupNextLContainerTitle">
                  {i18n.t('pages.online.episode.popupVote.further')}
                </span>
                <div
                  className="episodes__popupNextLContainer"
                  onClick={() =>
                    intercom.showNewMessage(
                      i18n.t('pages.online.episode.popupVote.intercom', {
                        episode: this.props.episode?.title,
                        program: this.props.program?.title.replace('$', ''),
                      }),
                    )
                  }
                >
                  <div className="episodes__popupNextLImg">
                    <img src={chatIcon} style={{ width: '50px' }} />
                  </div>
                  <div className="episodes__popupNextLText">
                    <div className="episodes__popupNextLTextTitle">
                      {i18n.t('pages.online.episode.popupVote.title2')}
                    </div>
                    <div className="episodes__popupNextLTextSubTitle">
                      {i18n.t('pages.online.episode.popupVote.subtitle2')}
                    </div>
                  </div>
                  <div className="episodes__popupNextLBtn"></div>
                </div>
                {episode.ressources.length > 0 && (
                  <div
                    className="episodes__popupNextLContainer"
                    onClick={() => {
                      this.setState({ endModalVisible: false, highLightDocs: true })
                    }}
                  >
                    <div className="episodes__popupNextLImg">
                      <img src={docsIcon} style={{ width: '50px' }} />
                    </div>
                    <div className="episodes__popupNextLText">
                      <div className="episodes__popupNextLTextTitle">
                        {i18n.t('pages.online.episode.popupVote.filesTitle')}
                      </div>
                      <div className="episodes__popupNextLTextSubTitle">
                        {i18n.t('pages.online.episode.popupVote.filesSub')}
                      </div>
                    </div>
                    <div className="episodes__popupNextLBtn"></div>
                  </div>
                )}
                {nextEpisode && (
                  <div style={{ width: '100%' }}>
                    <Separator text={i18n.t('pages.online.episode.popupVote.next')} />
                    <div
                      className="episodes__popupNextLContainer episodes__popupNextLContainerEp"
                      style={{ marginTop: 10, marginBottom: 0 }}
                      onClick={() => {
                        this.props.history.push('/online/program/' + program.id + '/episode/' + nextEpisode.id)
                      }}
                    >
                      <div className="episodes__popupNextLImg">
                        <div
                          className="episodesList__episodeCardImage"
                          style={{
                            backgroundImage: `url(${imageResizer(nextEpisode.picture, 'small')}`,
                          }}
                        ></div>
                      </div>
                      <div className="episodes__popupNextLText">
                        <div className="episodes__popupNextLTextTitle">{nextEpisode.title}</div>
                      </div>
                      <div className="episodes__popupNextLBtn"></div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Popup>
        <div className="episodes__descriptionContainer">
          {!episode.introduction && !episode.trailer && (
            <div className="episodes__descriptionLeftContainer">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  onClick={() => {
                    this.props.history.push('/online/program/' + program.id)
                  }}
                  theme={'grey'}
                  style={{
                    width: 'fit-content',
                  }}
                >
                  <ArrowLeft size={18} color={theme.colors.white} style={{ marginLeft: '0px', marginRight: '15px' }} />
                  {i18n.t('pages.online.episode.back')}
                </Button>
                {nextEpisode && (
                  <Button
                    onClick={() => {
                      this.props.history.push('/online/program/' + program.id + '/episode/' + nextEpisode.id)
                    }}
                    theme={'grey'}
                    style={{
                      width: 'fit-content',
                    }}
                  >
                    {i18n.t('pages.online.episode.next')}
                    <ArrowRight
                      size={18}
                      color={theme.colors.white}
                      style={{ marginLeft: '15px', marginRight: '0px' }}
                    />
                  </Button>
                )}
              </div>
              {episode.actions.length > 0 && (
                <div>
                  <GreyUpSpace text={i18n.t('pages.online.header.menu.actions')} key={`action-cards-title`} />
                  <div className={`${this.state.highLightDocs ? 'episodes__ressourcesHL' : ''}`}>
                    {episode.actions.map((a) => (
                      //@ts-ignore
                      <ActionCard text={a.title} subtext={a.description} key={`action-card-${a.id}`} id={a.id} />
                    ))}
                  </div>
                </div>
              )}
              {episode.ressources.length > 0 && (
                <div>
                  <GreyUpSpace text={i18n.t('pages.online.episode.files')} key={`ressources-cards-title`} />
                  <div className={`${this.state.highLightDocs ? 'episodes__ressourcesHL' : ''}`}>
                    {episode.ressources.map((r) => (
                      <SmallImageCard
                        image={r.image}
                        text={r.title}
                        subtext={r.type}
                        key={`ressources-card-${r.id}`}
                        actionType={'download'}
                        actionLink={r.file}
                        onLinkClick={this.onRessourceDL}
                      />
                    ))}
                  </div>
                </div>
              )}

              <GreyUpSpace text={i18n.t('pages.online.episode.memo')} />
              <div className="episodes__memo" style={{ backgroundImage: `url(${postit})` }}>
                <span>{episode.postit}</span>
              </div>
            </div>
          )}
          <div className="episodes__descriptionRightContainer">
            {(episode.introduction || episode.trailer) && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  onClick={() => {
                    this.props.history.push('/online/program/' + program.id)
                  }}
                  theme={'grey'}
                  style={{ marginBottom: '10px', width: 'fit-content' }}
                >
                  <ArrowLeft size={18} color={theme.colors.white} style={{ marginRight: '10px' }} />
                  {i18n.t('pages.online.episode.back')}
                </Button>
                {nextEpisode && (
                  <Button
                    onClick={() => {
                      this.props.history.push('/online/program/' + program.id + '/episode/' + nextEpisode.id)
                    }}
                    theme={'grey'}
                    style={{
                      width: 'fit-content',
                      marginBottom: '10px',
                    }}
                  >
                    {i18n.t('pages.online.episode.next')}
                    <ArrowRight
                      size={18}
                      color={theme.colors.white}
                      style={{ marginLeft: '15px', marginRight: '0px' }}
                    />
                  </Button>
                )}
              </div>
            )}
            <div className="episodes__videoContainer">
              <div id="episodeplayer"></div>
            </div>
            <div className="episodes__textEpisodeNumber">
              {episode.trailer ? i18n.t('pages.online.episode.trailer') : this.getEpisodeNumber()}
            </div>
            <div className="episodes__textEpisodeTitle">
              {episode.trailer ? program.title.replace('$', '') : episode.title}
            </div>
            {!episode.trailer && <div className="episodes__textEpisodeDescription">{episode.description}</div>}
            <GreyUpSpace text={i18n.t('pages.online.episode.by')} />
            <SmallMentorCard mentor={episode.mentor} program={program} onClick={this.onMentorClick} />
          </div>
        </div>
        {/*<div>
                  <GreyUpSpace text={i18n.t('pages.online.episode.next')} />
                  <div
                    className="episodes__popupNextLContainer episodes__popupNextLContainerEp"
                    style={{ marginTop: 10, marginBottom: 0 }}
                    onClick={() => {
                    }}
                  >
                    <div className="episodes__popupNextLImg">
                      <div
                        className="episodesList__episodeCardImage"
                        style={{
                          backgroundImage: `url(${imageResizer(nextEpisode.picture, 'small')}`,
                        }}
                      ></div>
                    </div>
                    <div className="episodes__popupNextLText">
                      <div className="episodes__popupNextLTextTitle">{nextEpisode.title}</div>
                    </div>
                    <div className="episodes__popupNextLBtn"></div>
                  </div>
                      </div>*/}
      </div>
    )
  }
  private onMentorClick = (mentor: IMentorObject, program: IProgramObject) => {
    this.player.pause()
    this.props.dispatch({ type: MENTOR_POPUP_OPEN, payload: { mentorId: mentor.id, programId: program.id } })
  }
  private onRessourceDL = (link: string) => {
    this.props.dispatch({ type: OPENNING_RESSOURCE, payload: { id: 0, title: link } })
  }
  private getEpisodeNumber = (): string => {
    //@ts-ignore
    if (this.props.episode.introduction) return i18n.t('pages.online.episode.intro')

    //@ts-ignore
    return i18n.t('pages.online.episode.episodeN', { number: this.props.episode.order })
  }
}
export default connect((store: IStore, props: IProps) => {
  let program = store.programs.program
  let episode: IEpisodeObject | undefined
  let nextEpisode: IEpisodeObject | undefined
  let view: IViewExtended | undefined
  let lang: 'fr' | 'en' = store.user.lang
  program = programTranslate(program, lang)
  if (program) {
    episode =
      program.episodes.find((e) => e.id === Number.parseInt(props.match.params.episodeId)) || program.episodes[0]
    if (!episode.videoid) {
      props.history.replace('/online/program/' + props.match.params.programId)
    }
    nextEpisode = isPlayableProgram(program)
      ? getNextEpisodeToPlay(program, Number.parseInt(props.match.params.episodeId), store.reader.views)
      : undefined
    // @ts-ignore
    view = store.reader.views.find((v) => v.episodeId === episode.id)
  }
  return {
    program,
    episode,
    nextEpisode,
    view,
    lang,
  }
})(Episode)
