import API from '../../tools/fetch'
import { AxiosResponse } from 'axios'

export const actionsFetchAction: () => Promise<false | number[]> = async () => {
  try {
    const request: AxiosResponse = await API.get('/private/actions/get')
    return request.data
  } catch (error) {
    return false
  }
}

export const actionSetAction: (actionId: number) => Promise<false | number[]> = async (actionId) => {
  try {
    const request: AxiosResponse = await API.post('/private/actions/set', { id: actionId })
    return request.data
  } catch (error) {
    return false
  }
}
