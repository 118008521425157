import React from 'react'
import './Loader.scss'
import loaderGif from '../../assets/images/loader.gif'

interface IProps {
  loading: boolean
  delay?: number
  displayMenu?: boolean
}
interface IState {
  displayed: boolean
}

export default class Loader extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = { displayed: props.loading }
  }
  public shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    if (this.state.displayed !== !nextState.displayed) {
    }
    if (!this.props.loading && nextProps.loading) {
      this.setState({ displayed: true })
    }
    if (this.props.loading && !nextProps.loading) {
      setTimeout(() => this.setState({ displayed: nextProps.loading }), this.props.delay || 1000)
    }
    return true
  }
  public render(): JSX.Element {
    if (!this.state.displayed) return <div />
    return (
      <div className={`loader__container ${this.props.displayMenu ? 'loader__containermenu' : ''}`}>
        <img src={loaderGif} />
      </div>
    )
  }
}
