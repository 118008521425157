import React from 'react'
import { IStore } from '../../../store'
import { connect } from 'react-redux'
import GreenLight from '../../../components/Texts/GreenLight'
import Button from '../../../components/Button'
import { ArrowLeft } from 'react-feather'
import theme from '../../../theme'
import GreyUpSpace from '../../../components/Texts/GreyUpScale'
import { IActionObject, IHomeProgramObject, IProgramObject } from '../../../store/programs/reducer'
import ActionCard from '../../../components/ActionCard'
import i18n from '../../../tools/i18n'
import API from '../../../tools/fetch'
import Loader from '../../../components/Loader'
interface IProps {
  programs: IHomeProgramObject[]
  actionIds: number[]
  dispatch: (object: { type: string; payload?: any }) => void
  navigation: any
  history: any
  lang: 'fr' | 'en'
}
interface IState {
  actions: IActionObject[]
  loading: boolean
}
class Actions extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = { actions: [], loading: true }
  }
  async componentDidMount() {
    let actions = await API.get('/private/actions/getDetails', {})
    this.setState({ actions: actions.data, loading: false })
  }
  public render(): JSX.Element | null {
    const { programs, actionIds, lang } = this.props
    const { loading, actions } = this.state

    return (
      <div
        style={{
          padding: '20px',
          maxWidth: ' 600px',
          display: 'flex',
          flexDirection: 'column',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        <Loader loading={loading} displayMenu={true} />
        <Button
          onClick={() => {
            this.props.history.push('/online/')
          }}
          theme={'grey'}
          style={{ width: '38px', padding: '10px 0' }}
        >
          <ArrowLeft size={18} color={theme.colors.white} />
        </Button>
        <GreenLight text={i18n.t('pages.online.actions.title')} />
        {actions.length < 1 && <div>{i18n.t('pages.online.actions.none')}</div>}
        {actions.map((a: IActionObject) => (
          //@ts-ignore
          <ActionCard
            key={`action-${a.id}`}
            text={lang === 'fr' ? a.title : a.titleEn}
            subtext={lang === 'fr' ? a.description : a.descriptionEn}
            id={a.id}
          />
        ))}
        {/*formated.map(
          (p) =>
            p.actions.length > 0 && (
              <div key={`program-${p.title}`} style={{ marginTop: 15 }}>
                <GreyUpSpace style={{ marginLeft: 14, marginBottom: 5 }} text={p.title} />
                {p.actions.map((a: IActionObject) => (
                  //@ts-ignore
                  <ActionCard key={`action-${a.id}`} text={a.title} subtext={a.description} id={a.id} />
                ))}
              </div>
            ),
                )*/}
      </div>
    )
  }
}

export default connect((store: IStore) => ({
  programs: store.programs.programs,
  actionIds: store.actions.actionIds,
  lang: store.user.lang,
}))(Actions)
