class StoreRegistry {
  private store: any
  public register: (store: any) => void = store => {
    this.store = store
  }
  public getStore: () => any = () => {
    return this.store
  }
}
export default new StoreRegistry()
