import React from 'react'
import { IStore } from '../../../store'
import { connect } from 'react-redux'
import theme from '../../../theme'
import inputs from './form'
import Form from '../../../components/Form'
import { USER_RESET_ERROR, USER_UPDATE_PWD } from '../../../store/user/constants'
import GreenLight from '../../../components/Texts/GreenLight'
import Button from '../../../components/Button'
import { ArrowLeft } from 'react-feather'
import i18n from '../../../tools/i18n'

interface IProps {
  dispatch: (object: { type: string; payload?: any }) => void
  navigation: any
  isRequesting: boolean
  error: boolean
  success: boolean
  history: any
}
interface IState {
  injectedErrors: { [key: string]: string[] }
}
class ChangePassword extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      injectedErrors: {},
    }
  }
  componentDidMount() {
    this.props.dispatch({ type: USER_RESET_ERROR })
  }
  public render(): JSX.Element | null {
    let df: any = []
    return (
      <div
        style={{
          padding: '20px',
          maxWidth: ' 600px',
          display: 'flex',
          flexDirection: 'column',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        <Button
          onClick={() => {
            this.props.history.push('/online/')
          }}
          theme={'grey'}
          style={{ width: '38px', padding: '10px 0' }}
        >
          <ArrowLeft size={18} color={theme.colors.white} />
        </Button>
        <GreenLight text={i18n.t('pages.online.password.title')} />
        <Form
          forms={inputs}
          defaultValues={df}
          buttonText={i18n.t('pages.online.password.btn')}
          onValidSubmit={this.onValidSubmit}
          loading={this.props.isRequesting}
          mainError={this.props.error}
          mainErrorText={i18n.t('pages.online.password.mainError')}
          success={this.props.success}
          successText={i18n.t('pages.online.password.success')}
          injectedErrors={this.state.injectedErrors}
        />
      </div>
    )
  }
  private injectErrors = (errors: { [key: string]: string[] }) => {
    this.setState({ injectedErrors: errors })
  }
  private onValidSubmit = (values: { [key: string]: string }) => {
    this.props.dispatch({
      type: USER_UPDATE_PWD,
      payload: {
        values,
        injectErrors: this.injectErrors,
      },
    })
  }
}

export default connect((store: IStore) => ({
  isRequesting: store.user.isRequesting,
  error: store.user.error,
  success: store.user.success,
}))(ChangePassword)
