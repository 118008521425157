import { IFormObject } from '../../../../components/Form'
import i18n from '../../../../tools/i18n'

const inputs: IFormObject[] = [
  {
    name: 'firstname',
    title: 'pages.offline.register.fields.firstname',
    type: 'text',
    half: true,
    validation: { presence: { allowEmpty: false, message: 'NONE' } },
  },
  {
    name: 'lastname',
    title: 'pages.offline.register.fields.lastname',
    type: 'text',
    half: true,
    validation: { presence: { allowEmpty: false, message: 'NONE' } },
  },
  {
    name: 'companyName',
    title: 'pages.offline.register.fields.companyName',
    type: 'text',
    half: false,
    validation: { presence: { allowEmpty: false, message: 'NONE' } },
  },
]

export default inputs
