import React from 'react'
import './MobileDisplay.scss'
import loaderGif from '../../assets/images/loader.gif'
import { isAndroid, isIOS } from 'react-device-detect'
import { Redirect } from 'react-router-dom'
import i18n from '../../tools/i18n'

export default class MobileDisplay extends React.Component {
  componentDidMount() {
    // @ts-ignore
    if (isAndroid) window.location = i18n.t('pages.components.mobileDisplay.playstoreUri')
    // @ts-ignore
    if (isIOS) window.location = i18n.t('pages.components.mobileDisplay.appstoreUri')
  }
  public render(): JSX.Element {
    if (isAndroid || isIOS) {
      return (
        <div className="mobileDisplay__container">
          <h1>{i18n.t('pages.components.mobileDisplay.h1')}</h1>
          <h2>{i18n.t('pages.components.mobileDisplay.h2')}</h2>
          <a href={i18n.t('pages.components.mobileDisplay.appstoreUri')}>
            {i18n.t('pages.components.mobileDisplay.appstore')}
          </a>
          <a href={i18n.t('pages.components.mobileDisplay.playstoreUri')}>
            {i18n.t('pages.components.mobileDisplay.playstore')}
          </a>
        </div>
      )
    }
    return (
      <div className="mobileDisplay__container">
        <h1>{i18n.t('pages.components.mobileDisplay.h1bis')}</h1>
        <a href={i18n.t('pages.components.mobileDisplay.appstoreUri')}>
          {i18n.t('pages.components.mobileDisplay.appstore')}
        </a>
        <a href={i18n.t('pages.components.mobileDisplay.playstoreUri')}>
          {i18n.t('pages.components.mobileDisplay.playstore')}
        </a>
      </div>
    )
  }
}
