import React, { CSSProperties } from 'react'
import './GreenLight.scss'

interface IProps {
  style?: CSSProperties
  text: string
}

export default class GreenLight extends React.PureComponent<IProps> {
  public render(): JSX.Element {
    return (
      <div className="greenLight">
        <span style={this.props.style}>{this.props.text.toUpperCase()}</span>
      </div>
    )
  }
}
