import React from 'react'
import StepManager from './Steps/stepManager'
import API from '../../../tools/fetch'
import { LOGIN } from '../../../store/user/constants'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import '../login/SignIn.scss'
import i18n from '../../../tools/i18n'
import { IStore } from '../../../store'

interface IProps {
  dispatch: (arg: { type: string; payload?: any }) => void
  lang: 'en' | 'fr'
}

class Register extends React.Component<IProps> {
  public render(): JSX.Element | null {
    return (
      <div className="signin">
        <div
          style={{ maxWidth: '300px', textAlign: 'center', fontWeight: 100, fontSize: '24px', marginBottom: '20px' }}
        >
          {i18n.t('pages.offline.register.title')}
        </div>
        <div style={{ maxWidth: '300px' }}>
          <StepManager dispatch={this.props.dispatch} lang={this.props.lang} />
        </div>
        <span className="signin__link">
          {i18n.t('pages.offline.register.alreadyAcc')}
          <Link to={'/offline/'}>{i18n.t('pages.offline.register.alreadyAccBtn')}</Link>
        </span>
      </div>
    )
  }
}

export default connect((store: IStore) => ({
  lang: store.user.lang,
}))(Register)
