let tokenStored: string | null
let tokenListener: (token: string) => void
const initToken: () => Promise<void | 1> = async () => {
  tokenStored = window.localStorage.token
}

const getToken: () => string | null = () => tokenStored

const setToken: (token: string) => Promise<void> = async (token) => {
  tokenStored = token
  window.localStorage.token = token
  tokenListener(token)
}

const unsetToken: () => Promise<void> = async () => {
  setToken('')
}

const setTokenListener = (listener: (token: string) => void) => {
  tokenListener = listener
}

export { getToken, setToken, initToken, unsetToken, setTokenListener }
