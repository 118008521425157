import { USER_LOGOUT } from '../user/constants'
import { MENTOR_POPUP_CLOSE, MENTOR_POPUP_OPEN } from './constants'

export interface IMentorPopupState {
  mentorId: number
  programId: number
  open: boolean
}

const initialState: IMentorPopupState = {
  mentorId: 0,
  programId: 0,
  open: false,
}

export default (
  state: IMentorPopupState = initialState,
  { type, payload }: { type: string; payload: any },
): IMentorPopupState => {
  switch (type) {
    case MENTOR_POPUP_OPEN:
      return {
        ...state,
        open: true,
        mentorId: payload.mentorId,
        programId: payload.programId,
      }
    case MENTOR_POPUP_CLOSE:
      return {
        ...state,
        open: false,
      }
    case USER_LOGOUT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
