import React from 'react'
import { connect } from 'react-redux'
import { IStore } from '../../../store/types'
import { IMentorObject, IProgramObject } from '../../../store/programs/reducer'
import 'reactjs-popup/dist/index.css'
import Popup from 'reactjs-popup'
import { MENTOR_POPUP_CLOSE } from '../../../store/mentorPopup/constants'
import './mentorPopup.scss'
import imageResizer from '../../../tools/imageResizer'
import GreyUpSpace from '../../../components/Texts/GreyUpScale'
import { Clock, Play, Tag, Users, X } from 'react-feather'
import theme from '../../../theme'
import PictoButton from '../../../components/PictoButton'
import loader from '../../../assets/images/loader.gif'
import i18n from '../../../tools/i18n'

interface IProps {
  match: any
  mentor?: IMentorObject
  mentorId: number
  programId: number
  lang: 'fr' | 'en'
  open: boolean
  history: any
  dispatch: (object: { type: string; payload?: any }) => void
}
interface IState {
  hideVideo: boolean
}

class MentorPopup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      hideVideo: true,
    }
  }
  componentDidUpdate(prevProps: IProps) {
    if (this.props.open && !prevProps.open) {
      this.setState({ hideVideo: true })
    }
  }

  public render(): JSX.Element {
    const { mentor, lang } = this.props
    if (!mentor) return <div></div>
    return (
      <Popup
        position="center center"
        modal={true}
        onClose={this.onClose}
        closeOnDocumentClick={this.state.hideVideo}
        open={this.props.open}
      >
        <Popup
          position="center center"
          className={'videoMentor'}
          modal={true}
          onClose={this.onCloseVideo}
          closeOnDocumentClick={!this.state.hideVideo}
          open={!this.state.hideVideo}
        >
          <div className="mentorPopup">
            <div id="mentorplayer">
              <img src={loader} width="40" height="40" />
            </div>
          </div>
        </Popup>
        <div className="mentorPopup">
          <div className="mentorPopup__leftCol">
            <div className="mentorPopup__mentorCard" style={{ backgroundImage: `url(${mentor.picture})` }}>
              {this.props.mentor?.videoId && this.props.mentor?.videoId !== '' && (
                <div className="mentorPopup__mentorCardButton" onClick={this.openVideo}>
                  <Play size={32} color={theme.colors.white} />
                </div>
              )}
            </div>
          </div>
          <div className="mentorPopup__mentorCardRightCol" style={{ position: 'relative' }}>
            <div>
              <PictoButton
                onClick={() => this.props.dispatch({ type: MENTOR_POPUP_CLOSE })}
                icon={<X size={18} color={theme.colors.white} />}
                style={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                }}
              />
            </div>
            <div className="mentorPopup__mentorCardText">
              {mentor.firstname} {mentor.lastname}
            </div>
            <div className="mentorPopup__mentorCardSubtext">
              {lang === 'fr' ? mentor.description : mentor.descriptionEn}
            </div>
            <div className="mentorPopup__mentorCardBio">{lang === 'fr' ? mentor.bio : mentor.bioEn}</div>
            {mentor.experiences.length > 0 && (
              <div className="mentorPopup__mentorCardExpCardContainer">
                <GreyUpSpace text={i18n.t('pages.online.mentor.experiences')} style={{ marginTop: 0 }} />
                {mentor.experiences.map((exp) => (
                  <div className="mentorPopup__mentorCardExpCard">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div>
                        <img src={imageResizer(exp.picture, 'small')} className="mentorPopup__mentorCardExpImg" />
                      </div>
                      <div>
                        <div className="mentorPopup__mentorCardExpTitle">{exp.company}</div>
                        <div className="mentorPopup__mentorCardExpSubTitle">{exp.job}</div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', padding: '5px 5px 1px 5px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          maxWidth: '50%',
                        }}
                      >
                        <Tag size={12} color={`${theme.colors.white}70`} />
                        <span
                          style={{
                            color: `${theme.colors.white}70`,
                            fontSize: '12px',
                            marginLeft: '5px',
                            lineHeight: '18px',
                          }}
                        >
                          {exp.tag}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: 10,
                          maxWidth: '35%',
                        }}
                      >
                        <Users size={12} color={`${theme.colors.white}70`} />
                        <span
                          style={{
                            color: `${theme.colors.white}70`,
                            fontSize: '12px',
                            marginLeft: '5px',
                            lineHeight: '18px',
                          }}
                        >
                          {i18n.t('pages.online.mentor.collabs', { count: exp.companySize.toString() })}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '10px',
                          maxWidth: '15%',
                        }}
                      >
                        <Clock size={12} color={`${theme.colors.white}70`} />
                        <span
                          style={{
                            color: `${theme.colors.white}70`,
                            fontSize: '12px',
                            marginLeft: '5px',
                            lineHeight: '18px',
                          }}
                        >
                          {i18n.t('pages.online.mentor.time', { count: exp.companyTime })}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Popup>
    )
  }
  private onClose = () => {
    this.props.dispatch({ type: MENTOR_POPUP_CLOSE })
  }
  private onCloseVideo = () => {
    this.setState({ hideVideo: true })
  }
  private openVideo = () => {
    this.setState({ hideVideo: false }, () => {
      setTimeout(this.loadVideo, 1000)
    })
  }
  private loadVideo = () => {
    if (this.props.mentor?.videoId && this.props.mentor?.videoId !== '')
      // @ts-ignore
      window.jwplayer('mentorplayer').setup({
        autostart: true,
        playlist: [
          {
            file: 'https://cdn.jwplayer.com/manifests/' + this.props.mentor?.videoId + '.m3u8',
          },
        ],
      })
    // @ts-ignore
    this.player = window.jwplayer('mentorplayer')
    // @ts-ignore
    this.player.on('complete', this.onCloseVideo)
  }
}
export default connect((store: IStore, props: IProps) => {
  let program: IProgramObject | undefined = store.programs.program
  let mentor: IMentorObject | undefined = undefined
  program?.episodes.forEach((e) => {
    if (e.mentor.id === props.mentorId) mentor = e.mentor
    if (e.mentorEn.id === props.mentorId) mentor = e.mentorEn
  })
  return {
    mentor,
    lang: store.user.lang,
  }
})(MentorPopup)
