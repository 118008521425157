import React from 'react'
import Form from '../../../../components/Form'
import inputs from './step1form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import i18n from '../../../../tools/i18n'

interface IProps {
  dispatch: (arg: { type: string; payload?: any }) => void
  onValidSubmit: (firstname: string, lastname: string, companyName: string) => void

  lang: 'en' | 'fr'
}

interface IState {
  requesting: boolean
  errored: boolean
  injectedErrors: { [key: string]: string[] }
}

class Register extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      requesting: false,
      errored: false,
      injectedErrors: {},
    }
  }

  public render(): JSX.Element | null {
    return (
      <div>
        <div style={{ marginTop: '-5px', marginBottom: '10px' }}>{i18n.t('pages.offline.register.step1')}</div>
        <Form
          forms={inputs}
          defaultValues={[]}
          buttonText={i18n.t('pages.offline.register.nextBtn')}
          onValidSubmit={this.onValidSubmit}
          loading={this.state.requesting}
          mainError={this.state.errored}
          mainErrorText={i18n.t('pages.offline.register.mainError')}
          injectedErrors={this.state.injectedErrors}
          lang={this.props.lang}
        />
      </div>
    )
  }

  private onValidSubmit = (values: { [key: string]: string }) => {
    this.props.onValidSubmit(values.firstname, values.lastname, values.companyName)
  }
}

export default connect()(Register)
