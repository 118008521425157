import React, { CSSProperties } from 'react'
import { Check, Lock } from 'react-feather'
import theme from '../../../../theme'

interface IProps {
  children: JSX.Element
  number?: number
  completed?: boolean
  locked?: boolean
}

export default class ItemContainer extends React.PureComponent<IProps> {
  public render(): JSX.Element {
    const { children, number, completed, locked } = this.props
    return (
      <div className="episodesList__itemContainer">
        <div className="episodesList__itemContainerContainer">
          {number && (
            <div
              className="episodesList__itemContainerNumberContainer"
              style={{
                backgroundColor: completed ? theme.colors.primary.regular : `${theme.colors.white}30`,
              }}
            >
              {!locked ? (
                !completed ? (
                  <span>{number.toString()}</span>
                ) : (
                  <Check
                    size={18}
                    color={theme.colors.greys.regular}
                    style={{
                      textAlign: 'center',
                      padding: 6,
                    }}
                  />
                )
              ) : (
                <Lock
                  size={18}
                  color={theme.colors.white}
                  style={{
                    textAlign: 'center',
                    padding: 6,
                  }}
                />
              )}
            </div>
          )}
          <div className="episodesList__itemContainerBar">
            <div className="episodesList__itemContainerBarBar"></div>
            <div className="episodesList__itemContainerBarFil"></div>
          </div>
        </div>
        <div style={{ flex: 1 }}>{children}</div>
      </div>
    )
  }
}
