import React from 'react'
import { connect } from 'react-redux'
import { IStore } from '../../../store/types'
import { IViewExtended } from '../../../store/reader/reducer'
import { ICategoriesProg, IHomeProgramObject, IProgramObject } from '../../../store/programs/reducer'
import Loader from '../../../components/Loader'
import ProgramCard from './ProgramCard'
import './HomeV3.scss'
import { getProgramPercent, getProgramPercentHome, isPlayableProgram } from '../../../store/programs/tools'
import i18n from '../../../tools/i18n'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
    slidesToSlide: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1140 },
    items: 3,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1140, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}
interface IProps {
  navigation: any
  loading: boolean
  error: boolean
  views: IViewExtended[]
  programs: IHomeProgramObject[]
  categories: ICategoriesProg[]
  history: any
  lang: 'en' | 'fr'
  loadingPrograms: boolean
  dispatch: (object: { type: string; payload?: any }) => void
}

class HomeV3 extends React.Component<IProps, any> {
  render() {
    const { loading, programs, views, categories } = this.props
    if (this.props.loadingPrograms || loading) {
      return <Loader loading={this.props.loadingPrograms} />
    }
    const mine = this.getMine()
    return (
      <div className="homev3">
        <Loader loading={loading} />
        {mine.length > 0 && (
          <div>
            <p className="homev3__titlecontainer">{i18n.t('pages.online.home.mine')}</p>
            <div>
              <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={false}
                autoPlay={false}
                autoPlaySpeed={100000000000}
              >
                {mine.map((program) => {
                  return (
                    <ProgramCard
                      program={program}
                      views={views}
                      goToProgram={this.goToProgram}
                      lang={this.props.lang}
                    />
                  )
                })}
              </Carousel>
            </div>
          </div>
        )}
        {categories.map((c) => (
          <div>
            <p className="homev3__titlecontainer">{this.props.lang === 'fr' ? c.categoryName : c.categoryNameEn}</p>
            <div>
              <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={false}
                autoPlay={false}
                autoPlaySpeed={100000000000}
              >
                {c.programs.map((programId) => {
                  let program = programs.find((p) => p.id === programId)
                  if (!program) return null
                  return (
                    <ProgramCard
                      program={program}
                      views={views}
                      goToProgram={this.goToProgram}
                      lang={this.props.lang}
                    />
                  )
                })}
              </Carousel>
            </div>
          </div>
        ))}
      </div>
    )
  }
  private goToProgram = (programId: number) => {
    this.props.history.push('/online/program/' + programId + '/')
  }

  private getMine = (): IHomeProgramObject[] => {
    let myProgs: { av: number; p: IHomeProgramObject }[] = []
    this.props.programs.forEach((p) => {
      if (isPlayableProgram(p)) {
        myProgs.push({ av: getProgramPercentHome(p, this.props.views), p: p })
      }
    })
    myProgs.sort(function (a, b) {
      return a.av - b.av
    })

    return myProgs.map((pav) => pav.p)
  }
}
export default connect((store: IStore) => ({
  loading: store.programs.isRequesting,
  error: store.programs.error,
  programs: store.programs.programs,
  categories: store.programs.categories,
  views: store.reader.views,
  lang: store.user.lang,
  loadingPrograms: store.programs.isRequesting,
}))(HomeV3)
