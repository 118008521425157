import API from '../../tools/fetch'
import { AxiosResponse } from 'axios'
import { IProgramObject } from './reducer'
import { IViewExtended } from '../reader/reducer'

export const programsFetchAction: any = async () => {
  try {
    const request: AxiosResponse = await API.get('/private/programs/home', { params: { lang: 'fr' } })
    return request.data
  } catch (error) {
    return false
  }
}
export const programFetchAction: any = async (programId: number) => {
  try {
    const request: AxiosResponse = await API.get('/private/programs/details', { params: { lang: 'fr', programId } })
    return request.data
  } catch (error) {
    return false
  }
}

export const viewsUpdateAction: any = async (views: IViewExtended[]) => {
  try {
    const request: AxiosResponse = await API.post('/private/views/update', { views })
    return request.data
  } catch (error) {
    return false
  }
}
