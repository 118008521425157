import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import Popup from 'reactjs-popup'
import Loader from '../../components/Loader'
import { IStore } from '../../store'
import { PROGRAM_FETCH } from '../../store/programs/constants'
import { IUserObject } from '../../store/types'
import Episode from './Episode'
import MentorPopup from './MentorPopup'
import ProgramDetails from './ProgramDetails'

interface IProps {
  match: any
  history: any
  dispatch: any
  open: boolean
  mentorId: number
  programId: number
  programLoading: boolean
  lang: 'fr' | 'en'
}

class ProgramRouter extends React.Component<IProps> {
  public componentDidMount() {
    this.props.dispatch({
      type: PROGRAM_FETCH,
      payload: { programId: Number.parseInt(this.props.match.params.programId) },
    })
  }
  public componentWillUnmount() {}
  render() {
    return (
      <div>
        <Loader loading={this.props.programLoading} displayMenu={true} />
        <MentorPopup {...this.props} />
        {!this.props.programLoading && (
          <Switch>
            <Route exact path="/online/program/:programId/episode/:episodeId" component={Episode} />

            <Route exact path="/online/program/:programId" component={ProgramDetails} />

            <Redirect to="/online/" />
          </Switch>
        )}
      </div>
    )
  }
}

export default connect((store: IStore) => ({
  open: store.mentorPopup.open,
  mentorId: store.mentorPopup.mentorId,
  programId: store.mentorPopup.programId,
  programLoading: store.programs.programRequesting,
  lang: store.user.lang,
}))(ProgramRouter)
