import { takeLatest, put, call, select, delay } from 'redux-saga/effects'
import {
  PROGRAMS_FETCH,
  PROGRAMS_FETCH_SUCCESS,
  PROGRAMS_FETCH_REQUESTING,
  PROGRAMS_FETCH_ERROR,
  PROGRAM_FETCH,
  PROGRAM_FETCH_REQUESTING,
  PROGRAM_FETCH_ERROR,
  PROGRAM_FETCH_SUCCESS,
} from './constants'
import { programFetchAction, programsFetchAction, viewsUpdateAction } from './actions'
import { IProgramObject } from './reducer'
import { IStore } from '..'
import { IViewExtended } from '../reader/reducer'
import { VIEWS_UPDATE, VIEWS_UPDATE_SUCCESS } from '../reader/constants'
import { ACTIONS_FETCH } from '../actions/constants'
import { PROGRAM_OPENED } from '../trackEvent/constants'

export default function* userWatcher(): IterableIterator<any> {
  yield takeLatest([VIEWS_UPDATE], viewsUpdate)
  yield takeLatest([PROGRAM_FETCH], programFetch)
  return yield takeLatest([PROGRAMS_FETCH], programsFetch)
}

function* programsFetch({ payload }: any): any {
  yield put({ type: PROGRAMS_FETCH_REQUESTING })
  const data: IProgramObject[] | false = yield call(programsFetchAction, payload)
  if (!data) {
    return yield put({
      type: PROGRAMS_FETCH_ERROR,
    })
  }
  yield put({
    type: PROGRAMS_FETCH_SUCCESS,
    payload: data,
  })
  yield put({
    type: VIEWS_UPDATE,
  })

  yield delay(3000)

  return yield put({
    type: ACTIONS_FETCH,
  })
}
function* programFetch({ payload }: any): any {
  yield put({ type: PROGRAM_FETCH_REQUESTING })
  const data: IProgramObject[] | false = yield call(programFetchAction, payload.programId)
  if (!data) {
    return yield put({
      type: PROGRAM_FETCH_ERROR,
    })
  }
  console.log('pppppppp', data)
  yield put({
    type: PROGRAM_FETCH_SUCCESS,
    payload: data,
  })
  return put({
    type: PROGRAM_OPENED,
    payload: { program: data },
  })
}

function* viewsUpdate({ payload }: any): any {
  const views: number[] = yield select((state: IStore) => state.reader.views)
  const data: IViewExtended[] | false = yield call(viewsUpdateAction, views)
  if (data) {
    return yield put({
      type: VIEWS_UPDATE_SUCCESS,
      payload: data,
    })
  }
}
